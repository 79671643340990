import React, { useEffect, useState } from 'react'
import { MetricOverview } from '@/admin/api/dashboard/types'
import { dashboardService } from '@/admin/api/dashboard/dashboard-service'
import { Card } from '@/shared/components/ui/card'

interface MetricCardProps {
  title: string
  value: string | number
  trend?: string
  unit?: string
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value, trend, unit }) => {
  const trendColor = trend?.startsWith('+') ? 'text-green-400' : 'text-red-400'
  return (
    <Card>
      <div className='metric-card-title'>{title}</div>
      <div className='metric-card-value'>
        {unit && <span className='text-xl ml-1'>{unit}</span>}
        {value && <span className='text-xl ml-1'>{value}</span>}
      </div>
      {trend && <span className={`text-sm ${trendColor}`}>{trend}</span>}
    </Card>
  )
}

export const MetricsOverview: React.FC = () => {
  const [metrics, setMetrics] = useState<MetricOverview[]>([])

  useEffect(() => {
    const fetchMetrics = async () => {
      const data = await dashboardService.getMetricOverviews()
      setMetrics(data)
    }
    fetchMetrics()
  }, [])

  return (
    <div className='grid grid-cols-5 gap-4'>
      {metrics.map((metric) => (
        <MetricCard
          key={metric.label}
          title={metric.label}
          value={metric.value}
          trend={metric.change}
          unit={metric.unit}
        />
      ))}
    </div>
  )
}
