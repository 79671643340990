import { useState, useCallback } from 'react'
import type {
  ComposerContext,
  ComposerResponse,
  LearningEvent,
  LearningSystemConfig,
  PerformanceMetrics,
  ComposerStage,
} from '../types'

const DEFAULT_CONFIG: LearningSystemConfig = {
  learningRate: 0.01,
  maxPatterns: 1000,
  confidenceThreshold: 0.8,
}

export function useLearningSystem() {
  const [config, setConfig] = useState<LearningSystemConfig>(DEFAULT_CONFIG)
  const [metrics, setMetrics] = useState<
    Pick<PerformanceMetrics, 'responseTime' | 'accuracy' | 'successRate'>
  >({
    responseTime: 0,
    accuracy: 0,
    successRate: 0,
  })

  const handleEvent = useCallback((event: LearningEvent) => {
    if (event.type === 'METRICS_UPDATED' && 'responseTime' in event.data) {
      const metricsData = event.data as PerformanceMetrics
      setMetrics({
        responseTime: metricsData.responseTime,
        accuracy: metricsData.accuracy,
        successRate: metricsData.successRate,
      })
    }
  }, [])

  const processInsight = useCallback((context: ComposerContext): ComposerResponse => {
    const { confidence = 0.8, stage = 'idle' as ComposerStage } = context
    return {
      nextStage: stage,
      confidence,
      message: `Processing ${stage} request`,
      metadata: {
        timestamp: Date.now(),
        source: 'learning_system',
      },
    }
  }, [])

  const updateConfig = useCallback((newConfig: Partial<LearningSystemConfig>) => {
    setConfig(prev => ({ ...prev, ...newConfig }))
  }, [])

  return {
    config,
    metrics,
    handleEvent,
    processInsight,
    updateConfig,
  }
}
