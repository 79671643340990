import React from 'react'
import { Line } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
)

interface DataPoint {
  timestamp: number
  value: number
  label?: string
}

interface LineChartProps {
  data: DataPoint[]
  className?: string
  height?: number
  yLabel?: string
}

export const LineChart: React.FC<LineChartProps> = ({
  data,
  className,
  height = 300,
  yLabel = '',
}) => {
  const chartData = {
    labels: data.map(d => new Date(d.timestamp).toLocaleDateString()),
    datasets: [
      {
        label: yLabel,
        data: data.map(d => d.value),
        borderColor: '#10b981',
        backgroundColor: '#10b98120',
        tension: 0.4,
        fill: true,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#94a3b8',
        },
      },
      y: {
        grid: {
          color: '#334155',
        },
        ticks: {
          color: '#94a3b8',
        },
      },
    },
  }

  return (
    <div className={className} style={{ height }}>
      <Line data={chartData} options={options} />
    </div>
  )
}
