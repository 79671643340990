import React from 'react'
import clsx from 'clsx'

export interface CardProps extends React.HTMLAttributes<HTMLDivElement> {
  variant?: 'default' | 'outline' | 'filled'
  padding?: 'none' | 'small' | 'medium' | 'large'
  shadow?: 'none' | 'small' | 'medium' | 'large'
  children: React.ReactNode
}

const variantStyles = {
  default: 'dark:bg-bg-nav',
  gray: 'bg-white dark:bg-gray-800',
  outline: 'border border-gray-200 dark:border-gray-700 bg-transparent',
  filled: 'bg-gray-50 dark:bg-gray-900',
}

const paddingStyles = {
  none: 'p-0',
  small: 'px-3 py-2',
  medium: 'px-6 py-4',
  large: 'px-8 py-6',
}

const shadowStyles = {
  none: 'shadow-none',
  small: 'shadow-sm',
  medium: 'shadow',
  large: 'shadow-lg',
}

export const Card: React.FC<CardProps> = ({
  variant = 'default',
  padding = 'medium',
  shadow = 'small',
  className,
  children,
  ...props
}) => {
  return (
    <div
      className={clsx(
        'rounded-lg',
        variantStyles[variant],
        paddingStyles[padding],
        shadowStyles[shadow],
        'transition-shadow duration-200',
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}
