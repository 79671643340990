import React from 'react'
import { NavLink } from 'react-router-dom'
import { Share2, Download, Filter } from 'lucide-react'
import { Card } from '@/shared/components/ui/card'

interface RecycleItem {
  id: string
  facility: string
  dateReceived: string
  status: string
  weight: number
}

const mockItems: RecycleItem[] = [
  {
    id: 'LRPLY00545897',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:43',
    status: 'Sent to Recycler',
    weight: 0.43,
  },
  {
    id: 'LRPLY00354861',
    facility: 'CAON015\nOntario, United States',
    dateReceived: '10 Oct 23 at 10:42',
    status: 'Sent to Recycler',
    weight: 0.85,
  },
  {
    id: 'LRPLY00354862',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:41',
    status: 'In Storage',
    weight: 0.62,
  },
  {
    id: 'LRPLY00354863',
    facility: 'CAON015\nOntario, United States',
    dateReceived: '10 Oct 23 at 10:40',
    status: 'Assessment',
    weight: 0.91,
  },
  {
    id: 'LRPLY00354864',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:39',
    status: 'Received',
    weight: 0.73,
  },
]

export const RecycleInformationPage: React.FC = () => {
  return (
    <div>
      <header className='flex items-center justify-between mb-6'>
        <h2 className='text-xl font-semibold text-neutral-100'>Recycle Information</h2>
        <div className='flex gap-2'>
          <button className='flex items-center gap-1 bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700 border border-neutral-700'>
            <Share2 size={16} />
            Share
          </button>
          <button className='flex items-center gap-1 bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700 border border-neutral-700'>
            <Download size={16} />
            Download
          </button>
          <button className='flex items-center gap-1 bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700 border border-neutral-700'>
            <Filter size={16} />
            Filters
          </button>
        </div>
      </header>

      <Card className='bg-neutral-800 rounded-lg overflow-hidden border border-neutral-700'>
        <table className='w-full text-sm text-neutral-200'>
          <thead className='border-b border-neutral-700 text-neutral-400'>
            <tr>
              <th className='py-3 text-left px-4'>ITEM ID</th>
              <th className='py-3 text-left px-4'>FACILITY</th>
              <th className='py-3 text-left px-4'>DATE RECEIVED</th>
              <th className='py-3 text-left px-4'>STATUS</th>
              <th className='py-3 text-left px-4'>WEIGHT (KGS)</th>
              <th className='py-3 px-4'></th>
            </tr>
          </thead>
          <tbody>
            {mockItems.map((item, i) => (
              <tr
                key={i}
                className='border-b border-neutral-700 hover:bg-neutral-700 transition-colors'
              >
                <td className='py-3 px-4'>{item.id}</td>
                <td className='py-3 px-4 whitespace-pre'>{item.facility}</td>
                <td className='py-3 px-4'>{item.dateReceived}</td>
                <td className='py-3 px-4'>
                  <span className='bg-neutral-700 text-neutral-200 py-1 px-2 rounded'>
                    {item.status}
                  </span>
                </td>
                <td className='py-3 px-4'>{item.weight.toFixed(2)}</td>
                <td className='py-3 px-4 text-right'>
                  <NavLink
                    to={`/admin/recycle/recycle-information/${item.id}`}
                    className='text-neutral-400 hover:text-neutral-100 flex items-center gap-1'
                  >
                    Details
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <div className='flex items-center justify-between mt-4 text-neutral-300'>
        <div>Page 1 of 13</div>
        <div className='flex items-center gap-2'>
          <button className='bg-neutral-800 px-3 py-1 rounded border border-neutral-700 hover:bg-neutral-700'>
            &lt;
          </button>
          <button className='bg-neutral-800 px-3 py-1 rounded border border-neutral-700 hover:bg-neutral-700'>
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}
