import { StreamEvent, ModelUpdate } from './types'

export const mockStreamEvents: StreamEvent[] = [
    {
        id: 'evt_1',
        type: 'learning_progress',
        message: 'Pattern recognition model updated with new training data',
        confidence: 0.98,
        timestamp: Date.now() - 300000
    },
    {
        id: 'evt_2',
        type: 'anomaly_detection',
        message: 'Detected unusual return pattern in electronics category',
        confidence: 0.89,
        timestamp: Date.now() - 600000
    }
]

export const mockModelUpdates: ModelUpdate[] = [
    {
        version: 'v2.3.1',
        timestamp: Date.now() - 172800000, // 2 days ago
        changes: ['Improved pattern recognition', 'Enhanced confidence scoring'],
        status: 'Active',
    }
] 