import React from 'react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'

export const ResaleOverviewPage: React.FC = () => {
  return (
    <div className='space-y-8'>
      <Text variant='h3'>Overview</Text>

      <div className='grid grid-cols-3 gap-6'>
        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Material Type Distribution
          </Text>
          <BarChart
            data={[
              { label: 'Target', value: 82 },
              { label: 'Non-target', value: 18 },
            ]}
            className='h-32'
          />
          <div className='text-sm text-neutral-400 mt-2'>
            <div>Target: 82%</div>
            <div>Non-target: 18%</div>
          </div>
        </Card>

        <Card className='p-4 col-span-2'>
          <Text variant='label' className='text-neutral-400'>
            Monthly Throughput
          </Text>
          <LineChart
            data={[
              { timestamp: 1701734400000, value: 234 },
              { timestamp: 1701648000000, value: 1032 },
              { timestamp: 1701561600000, value: 856 },
              { timestamp: 1701475200000, value: 945 },
              { timestamp: 1701388800000, value: 1123 },
            ]}
            className='h-32'
          />
          <div className='text-sm text-neutral-400 mt-2'>
            <div>Today: 234 items</div>
            <div>Yesterday: 1,032 items</div>
          </div>
        </Card>
      </div>

      <div className='grid grid-cols-4 gap-6'>
        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Total Items Listed
          </Text>
          <Text variant='h3' className='mt-2'>
            1,234
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Average Sale Price
          </Text>
          <Text variant='h3' className='mt-2'>
            $45.67
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Revenue This Month
          </Text>
          <Text variant='h3' className='mt-2'>
            $56,789
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Carbon Savings
          </Text>
          <Text variant='h3' className='mt-2'>
            0.08mt
          </Text>
        </Card>
      </div>
    </div>
  )
}
