import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'
import { Text } from '@/shared/components/ui/text'
import { LineChart, BarChart } from '@/shared/components/ui/charts'

export function RepairOverviewPage() {
  return (
    <div className='space-y-8'>
      <Text variant='h2' className='mb-6'>
        Overview
      </Text>

      <Grid columns={4} gap={4} className='mb-6'>
        <Card>
          <div className='metric-card-title'>Active Repairs</div>
          <div className='metric-card-value'>
            56 <span className='trend-positive'>+12%</span>
          </div>
        </Card>
        <Card>
          <div className='metric-card-title'>Completed Today</div>
          <div className='metric-card-value'>
            12 <span className='trend-positive'>+3</span>
          </div>
        </Card>
        <Card>
          <div className='metric-card-title'>Success Rate</div>
          <div className='metric-card-value'>
            94% <span className='trend-positive'>+2.1%</span>
          </div>
        </Card>
        <Card>
          <div className='metric-card-title'>Average Time</div>
          <div className='metric-card-value'>
            2.5h <span className='trend-negative'>+0.4h</span>
          </div>
        </Card>
      </Grid>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6'>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Repair Volume</h2>
          <p className='text-sm text-text-secondary mb-4'>Daily repair requests and completions</p>
          <BarChart
            data={[
              { label: 'Mon', value: 24 },
              { label: 'Tue', value: 18 },
              { label: 'Wed', value: 32 },
              { label: 'Thu', value: 28 },
              { label: 'Fri', value: 22 },
            ]}
            className='h-64'
          />
        </Card>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Processing Time Trend</h2>
          <p className='text-sm text-text-secondary mb-4'>Average repair time in hours</p>
          <LineChart
            data={[
              { timestamp: 1701388800000, value: 2.1 },
              { timestamp: 1701475200000, value: 2.3 },
              { timestamp: 1701561600000, value: 2.4 },
              { timestamp: 1701648000000, value: 2.6 },
              { timestamp: 1701734400000, value: 2.5 },
            ]}
            className='h-64'
          />
        </Card>
      </div>

      <Grid className='grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        <Card>
          <h3 className='text-lg font-semibold mb-4 text-text-primary'>Top Repair Types</h3>
          <div className='space-y-3'>
            {[
              { name: 'Sole Replacement', value: '32%' },
              { name: 'Stitching', value: '28%' },
              { name: 'Insole', value: '24%' },
            ].map(category => (
              <div key={category.name} className='flex justify-between items-center'>
                <span className='text-text-secondary'>{category.name}</span>
                <span className='text-text-primary font-medium'>{category.value}</span>
              </div>
            ))}
          </div>
        </Card>
        <Card>
          <h3 className='text-lg font-semibold mb-4 text-text-primary'>Technician Status</h3>
          <div className='space-y-4'>
            <div className='progress-container'>
              <div className='progress-step completed'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Available</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step completed'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Working</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Break</span>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <h3 className='text-lg font-semibold mb-4 text-text-primary'>Recent Activity</h3>
          <div className='space-y-4'>
            <div className='tracking-event first'>
              <div className='circle' />
              <div>
                <div className='title'>Repair completed</div>
                <div className='location'>Station #3</div>
                <div className='date'>2 hours ago</div>
              </div>
            </div>
            <div className='tracking-event'>
              <div className='circle' />
              <div>
                <div className='title'>New repair started</div>
                <div className='location'>Station #1</div>
                <div className='date'>4 hours ago</div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
