import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'
import { Text } from '@/shared/components/ui/text'
import { Metric } from '@/shared/components/ui/metric'
import { LineChart } from '@/shared/components/ui/charts/line-chart'
import { PieChart } from '@/shared/components/ui/charts'
import { useRefillAnalytics } from '@/admin/hooks/use-refill-analytics'

export function RefillOverviewPage() {
  const { subscriptionAnalytics, containerAnalytics } = useRefillAnalytics()

  const subscriptionData = React.useMemo(() => {
    return Object.entries(subscriptionAnalytics.subscriptionsByFrequency).map(([freq, count]) => ({
      timestamp: Date.now(),
      value: count as number,
      label: freq,
    }))
  }, [subscriptionAnalytics.subscriptionsByFrequency])

  const returnCompositionData = React.useMemo(
    () => [
      { label: 'Target Packaging', value: 97 },
      { label: 'Non-target Returns', value: 3 },
    ],
    []
  )

  return (
    <div className='space-y-8'>
      <Text variant='h2' className='mb-6'>
        Overview
      </Text>

      <Grid columns={3} gap={6}>
        {/* Return Composition chart */}
        <Card>
          <Text variant='h3' className='mb-2'>
            Return Composition
          </Text>
          <div className='w-full h-48'>
            <PieChart
              data={returnCompositionData}
              colors={['#04cfa8', '#e4c167']}
              className='h-40'
            />
          </div>
          <div className='mt-2 text-sm text-neutral-600'>
            <Text>Target Packaging: 97%</Text>
            <Text>Non-target Returns: 3%</Text>
          </div>
        </Card>

        {/* Plastic Source Reduction metric */}
        <Card className='col-span-2'>
          <Text variant='h3' className='mb-2'>
            Plastic Source Reduction
          </Text>
          <LineChart data={subscriptionData} height={200} yLabel='Reduction (kg)' />
          <Text className='mt-2 text-sm text-neutral-600'>
            Showing cumulative reduction over time
          </Text>
        </Card>

        {/* Processing Time */}
        <Card>
          <Text>Processing Time</Text>
          <Metric>19 mins</Metric>
          <Text className='text-sm text-neutral-600'>Average per container</Text>
        </Card>

        {/* CO2 Saved */}
        <Card>
          <Text>CO2 Saved</Text>
          <Metric>5.2 tons</Metric>
          <Text className='text-sm text-neutral-600'>This month</Text>
        </Card>

        {/* Average Return Rate */}
        <Card>
          <Text>Average Return Rate</Text>
          <Metric>92%</Metric>
          <Text className='text-sm text-neutral-600'>Last 30 days</Text>
        </Card>

        {/* Total in Circulation */}
        <Card>
          <Text>Total in Circulation</Text>
          <Metric>{containerAnalytics.totalContainers}</Metric>
          <Text className='text-sm text-neutral-600'>Active containers</Text>
        </Card>

        {/* Cost of Program */}
        <Card>
          <Text>Cost of Program</Text>
          <Metric>$12,345</Metric>
          <Text className='text-sm text-neutral-600'>Monthly operating cost</Text>
        </Card>

        {/* Total Refills */}
        <Card>
          <Text>Total Refills</Text>
          <Metric>45,678</Metric>
          <Text className='text-sm text-neutral-600'>Since program start</Text>
        </Card>
      </Grid>
    </div>
  )
}
