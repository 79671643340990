import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'
import { Text } from '@/shared/components/ui/text'

export function RepairProgramPage() {
  return (
    <div className='space-y-6'>
      <div className='border-b border-border-base pb-4'>
        <Text variant='h1' className='mb-2'>
          Repair Program
        </Text>
        <Text variant='body' className='text-text-secondary'>
          Manage repair operations, technicians, and analytics
        </Text>
      </div>

      <nav className='flex space-x-4 border-b border-border-base'>
        <NavLink
          to='overview'
          className={({ isActive }) =>
            `px-4 py-2 text-sm font-medium ${
              isActive
                ? 'border-b-2 border-accent-purple text-text-primary'
                : 'text-text-secondary hover:text-text-primary'
            }`
          }
        >
          Overview
        </NavLink>
        <NavLink
          to='list'
          className={({ isActive }) =>
            `px-4 py-2 text-sm font-medium ${
              isActive
                ? 'border-b-2 border-accent-purple text-text-primary'
                : 'text-text-secondary hover:text-text-primary'
            }`
          }
        >
          Repair List
        </NavLink>
        <NavLink
          to='technicians'
          className={({ isActive }) =>
            `px-4 py-2 text-sm font-medium ${
              isActive
                ? 'border-b-2 border-accent-purple text-text-primary'
                : 'text-text-secondary hover:text-text-primary'
            }`
          }
        >
          Technicians
        </NavLink>
        <NavLink
          to='analytics'
          className={({ isActive }) =>
            `px-4 py-2 text-sm font-medium ${
              isActive
                ? 'border-b-2 border-accent-purple text-text-primary'
                : 'text-text-secondary hover:text-text-primary'
            }`
          }
        >
          Analytics
        </NavLink>
      </nav>

      <div className='py-4'>
        <Outlet />
      </div>
    </div>
  )
}
