import React, { useState } from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import OnLogo from '@/assets/logos/onLogo.svg'
import PentatonicLogo from '@/assets/logos/pentatonic-logo.svg'
import PentatonicLogoSmall from '@/assets/logos/pentatonic-logo-small.svg'
import UserImage from '@/assets/user/userPicture.png'
import { motion } from 'framer-motion'
import { Squash as Hamburger } from 'hamburger-react'

export function AdminLayout() {
  const location = useLocation()
  const [hamburgerOpen, setHamburgerOpen] = useState(false)

  const navItems = [
    { path: '/admin', label: 'Overview' },
    { path: '/admin/inbound', label: 'Inbound' },
    { path: '/admin/refills/overview', label: 'Refills' },
    { path: '/admin/repairs/overview', label: 'Repairs' },
    { path: '/admin/recycle/overview', label: 'Recycle' },
    { path: '/admin/resale/overview', label: 'Resale' },
    { path: '/admin/ai-learning', label: 'AI Learning' },
  ]

  return (
    <div className='min-h-screen bg-bg-main dark px-2 sm:px-4'>
      {/* placeholder for content */}
      <div className='top-0 left-0 right-0 h-20 bg-bg-main'></div>
      {/* blind to hide content above navbar */}
      <div className='fixed z-40 top-0 left-0 right-0 h-16 bg-bg-main/50 backdrop-blur-sm'></div>
      {/* Navbar */}
      <div className='fixed z-50 top-4 inset-x-0 px-2 sm:px-4'>
        <nav
          className='bg-bg-nav border border-border-base max-w-[80rem] rounded-xl mx-auto'
          style={{ filter: 'drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.8))' }}
        >
          <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-6'>
            <div className='relative flex items-center h-16 w-full justify-between'>
              {/* Left Logos */}
              <Link to='/admin' className='flex-shrink-0 flex items-center space-x-2 sm:space-x-3'>
                <img src={OnLogo} alt='On Logo' className='h-5' />
                <img src={PentatonicLogo} alt='Pentatonic Logo' className='h-3.5 hidden lg:block' />
                <img
                  src={PentatonicLogoSmall}
                  alt='Pentatonic Logo'
                  className='h-8 block lg:hidden'
                />
              </Link>

              {/* Center Nav Items */}
              <div className='absolute inset-0 mx-auto flex justify-center items-center w-fit'>
                <div className='hidden md:flex md:space-x-7 sm:space-x-6'>
                  {navItems.map(item => (
                    <Link
                      key={item.path}
                      to={item.path}
                      className={`inline-flex items-center px-1 pt-1 text-sm font-medium h-[64px] relative ${
                        location.pathname === item.path
                          ? 'text-text-primary'
                          : 'text-text-secondary hover:text-text-primary'
                      }`}
                    >
                      {item.label}
                      {location.pathname === item.path && (
                        <motion.div
                          className='absolute bottom-0 left-0 right-0 h-0.5 bg-accent-purple'
                          layoutId='underline'
                          initial={false}
                          style={{ originY: 'top' }}
                        />
                      )}
                    </Link>
                  ))}
                </div>
              </div>

              {/* Right Empty Div */}
              <div className='flex-shrink-0 flex items-center space-x-2 cursor-pointer'>
                <img
                  src={UserImage}
                  alt='User'
                  className='h-7 w-7 rounded-full border text-text-secondary group-hover:text-text-primary'
                />
                <div className='block md:hidden'>
                  <Hamburger
                    toggled={hamburgerOpen}
                    toggle={setHamburgerOpen}
                    size={24}
                    color='currentColor'
                  />
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>

      {/* Mobile menu */}
      {hamburgerOpen && (
        <div className='fixed inset-0 z-50 bg-bg-main/95 backdrop-blur-sm'>
          <div className='fixed inset-x-0 top-0 z-50 px-2 sm:px-4 py-4'>
            <nav className='bg-bg-nav border border-border-base rounded-xl mx-auto'>
              <div className='max-w-7xl mx-auto px-4 sm:px-6 lg:px-6'>
                <div className='relative flex items-center h-16 w-full justify-between'>
                  <Link
                    to='/admin'
                    className='flex-shrink-0 flex items-center space-x-2 sm:space-x-3'
                  >
                    <img src={OnLogo} alt='On Logo' className='h-5' />
                    <img
                      src={PentatonicLogo}
                      alt='Pentatonic Logo'
                      className='h-3.5 hidden lg:block'
                    />
                    <img
                      src={PentatonicLogoSmall}
                      alt='Pentatonic Logo'
                      className='h-8 block lg:hidden'
                    />
                  </Link>
                  <div className='flex-shrink-0 flex items-center'>
                    <Hamburger
                      toggled={hamburgerOpen}
                      toggle={setHamburgerOpen}
                      size={24}
                      color='currentColor'
                    />
                  </div>
                </div>
              </div>
            </nav>
          </div>
          <div className='fixed inset-0 z-40 pt-20 pb-6 px-4 sm:px-6 overflow-y-auto'>
            <div className='flex flex-col space-y-1'>
              {navItems.map(item => (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={() => setHamburgerOpen(false)}
                  className={`p-3 rounded-lg text-lg font-medium transition-colors ${
                    location.pathname === item.path
                      ? 'bg-bg-hover text-text-primary'
                      : 'text-text-secondary hover:bg-bg-hover hover:text-text-primary'
                  }`}
                >
                  {item.label}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}

      <main className='max-w-7xl mx-auto py-6'>
        <Outlet />
      </main>
    </div>
  )
}
