import React from 'react'
import { NavLink } from 'react-router-dom'
import { Share2, Download, Filter } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'

// Mock data
interface InboundItem {
  id: string
  facility: string
  dateReceived: string
  status: string
}

const mockInboundItems: InboundItem[] = [
  {
    id: 'LRPLY00545897',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:43',
    status: 'Received',
  },
  {
    id: 'LRPLY00354861',
    facility: 'CAON015\nOntario, United States',
    dateReceived: '10 Oct 23 at 10:42',
    status: 'Received',
  },
  // Add more mock items as needed
]

export const InboundListPage: React.FC = () => {
  return (
    <div className='text-neutral-100'>
      <header className='flex items-center justify-between mb-6'>
        <Text variant='h2'>Inbound</Text>
        <div className='flex gap-2'>
          {/* Region/date filters */}
          <button className='bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700'>
            Select date
          </button>
          <button className='bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700'>
            Select region
          </button>

          <button className='flex items-center gap-1 bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700 border border-neutral-700'>
            <Share2 size={16} />
            Share
          </button>
          <button className='flex items-center gap-1 bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700 border border-neutral-700'>
            <Download size={16} />
            Download
          </button>
          <button className='flex items-center gap-1 bg-neutral-800 text-neutral-200 py-2 px-3 rounded hover:bg-neutral-700 border border-neutral-700'>
            <Filter size={16} />
            Filters
          </button>
        </div>
      </header>

      <Card className='overflow-hidden'>
        <table className='w-full text-sm text-neutral-200'>
          <thead className='border-b border-neutral-700 text-neutral-400'>
            <tr>
              <th className='py-3 text-left px-4'>ITEM ID</th>
              <th className='py-3 text-left px-4'>FACILITY</th>
              <th className='py-3 text-left px-4'>DATE RECEIVED</th>
              <th className='py-3 text-left px-4'>STATUS</th>
              <th className='py-3 px-4'></th>
            </tr>
          </thead>
          <tbody>
            {mockInboundItems.map((item, i) => (
              <tr
                key={i}
                className='border-b border-neutral-700 hover:bg-bg-hover transition-colors'
              >
                <td className='py-3 px-4'>{item.id}</td>
                <td className='py-3 px-4 whitespace-pre'>{item.facility}</td>
                <td className='py-3 px-4'>{item.dateReceived}</td>
                <td className='py-3 px-4'>
                  <span className='bg-neutral-700 text-neutral-200 py-1 px-2 rounded'>
                    {item.status}
                  </span>
                </td>
                <td className='py-3 px-4 text-right'>
                  <NavLink
                    to={`/admin/inbound/${item.id}`}
                    className='text-neutral-400 hover:text-neutral-100 flex items-center gap-1'
                  >
                    Details
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <div className='flex items-center justify-between mt-4 text-neutral-300'>
        <div>Page 1 of 13</div>
        <div className='flex items-center gap-2'>
          <button className='bg-neutral-800 px-3 py-1 rounded border border-neutral-700 hover:bg-neutral-700'>
            &lt;
          </button>
          <button className='bg-neutral-800 px-3 py-1 rounded border border-neutral-700 hover:bg-neutral-700'>
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}
