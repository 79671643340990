import React from 'react'
import { cn } from '@/lib/utils'

interface MetricProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode
}

export function Metric({ children, className, ...props }: MetricProps) {
  return (
    <div className={cn('text-3xl font-semibold tracking-tight', className)} {...props}>
      {children}
    </div>
  )
}
