import { useState, useEffect, useRef } from 'react'
import { StreamEvent } from '@/admin/api/ai-learning/types'
import { mockStreamEvents } from '@/admin/api/ai-learning/mock-data'

export const useSimulatedAIEvents = () => {
    const [events, setEvents] = useState<StreamEvent[]>([mockStreamEvents[0]])
    const [isLoading, setIsLoading] = useState(false)
    const delayRef = useRef(3000)

    useEffect(() => {
        const addNewEvent = () => {
            setIsLoading(true)
            const randomEvent = mockStreamEvents[Math.floor(Math.random() * mockStreamEvents.length)]
            
            setTimeout(() => {
                const newEvent = {
                    ...randomEvent,
                    id: crypto.randomUUID(),
                    timestamp: Date.now()
                }
                setEvents(prev => [newEvent, ...prev])
                setIsLoading(false)
            }, 3000)
        }

        const scheduleNextEvent = () => {
            const nextDelay = delayRef.current + Math.random() * 10000
            delayRef.current = nextDelay * 1.1 // Increase by 10%
            
            setTimeout(() => {
                addNewEvent()
                scheduleNextEvent()
            }, nextDelay)
        }

        scheduleNextEvent()
        return () => {}
    }, [])

    return { events, isLoading }
}