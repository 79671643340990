import React from 'react'
import { NavLink } from 'react-router-dom'
import { ChevronRight, Download, Share2 } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'

interface RepairItem {
  id: string
  facility: string
  dateReceived: string
  status: string
}

// Mock data: Replace with real API integration or domain fetching logic.
const mockRepairItems: RepairItem[] = [
  {
    id: 'LRPLY00645987',
    facility: 'USCA005 • California, United States',
    dateReceived: '10 Oct 23 at 10:43',
    status: 'Received',
  },
  {
    id: 'LRPLY00354861',
    facility: 'CAON015 • Ontario, United States',
    dateReceived: '10 Oct 23 at 10:42',
    status: 'Received',
  },
]

export function RepairsListPage() {
  return (
    <div className='text-text-primary'>
      <nav className='flex items-center text-sm text-text-secondary mb-4'>
        <Text variant='label' className='font-medium'>
          Repairs
        </Text>
      </nav>
      <header className='flex items-center justify-between mb-6'>
        <Text variant='h1'>Repairs</Text>
        <div className='flex gap-2'>
          <button className='flex items-center gap-1 bg-bg-panel py-2 px-3 rounded hover:bg-bg-hover text-text-primary'>
            <Share2 size={16} />
            <span>Share</span>
          </button>
          <button className='flex items-center gap-1 bg-bg-panel py-2 px-3 rounded hover:bg-bg-hover text-text-primary'>
            <Download size={16} />
            <span>Download</span>
          </button>
        </div>
      </header>

      <div className='bg-bg-panel rounded-lg overflow-hidden shadow'>
        <table className='w-full text-sm'>
          <thead className='border-b border-border-base text-text-secondary'>
            <tr>
              <th className='py-3 text-left px-4'>ITEM ID</th>
              <th className='py-3 text-left px-4'>FACILITY</th>
              <th className='py-3 text-left px-4'>DATE RECEIVED</th>
              <th className='py-3 text-left px-4'>STATUS</th>
              <th className='py-3 px-4'></th>
            </tr>
          </thead>
          <tbody>
            {mockRepairItems.map((item, i) => (
              <tr
                key={i}
                className='border-b border-border-base hover:bg-bg-hover transition-colors'
              >
                <td className='py-3 px-4 text-text-primary'>{item.id}</td>
                <td className='py-3 px-4 text-text-secondary'>{item.facility}</td>
                <td className='py-3 px-4 text-text-secondary'>{item.dateReceived}</td>
                <td className='py-3 px-4'>
                  <span className='bg-bg-hover text-text-primary py-1 px-2 rounded'>
                    {item.status}
                  </span>
                </td>
                <td className='py-3 px-4 text-right'>
                  <NavLink
                    to={`/admin/repairs/${item.id}`}
                    className='text-text-secondary hover:text-text-primary flex items-center gap-1 justify-end'
                  >
                    Details <ChevronRight size={16} />
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className='flex items-center justify-between mt-4 text-text-secondary'>
        <div>Page 1 of 13</div>
        <div className='flex items-center gap-2'>
          <button className='bg-bg-panel px-3 py-1 rounded hover:bg-bg-hover text-text-primary'>
            &lt;
          </button>
          <button className='bg-bg-panel px-3 py-1 rounded hover:bg-bg-hover text-text-primary'>
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}
