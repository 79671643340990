import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { PieChart } from '@/shared/components/ui/charts'

// Mock data - replace with real data from your backend
const technicians = [
  {
    id: 1,
    name: 'John Smith',
    specialties: ['Sole Replacement', 'Stitching', 'Insole'],
    availability: 'Available',
    currentLoad: 2,
    efficiency: 94,
    completedToday: 5,
  },
  {
    id: 2,
    name: 'Sarah Johnson',
    specialties: ['Leather Work', 'Stitching', 'Color Restoration'],
    availability: 'Working',
    currentLoad: 4,
    efficiency: 96,
    completedToday: 7,
  },
  {
    id: 3,
    name: 'Mike Chen',
    specialties: ['Sole Replacement', 'Heel Repair', 'Custom Orthotics'],
    availability: 'Break',
    currentLoad: 1,
    efficiency: 92,
    completedToday: 4,
  },
]

const workloadData = [
  { label: 'Available Capacity', value: 65 },
  { label: 'Current Workload', value: 35 },
]

export function RepairTechniciansPage() {
  return (
    <div className='space-y-8'>
      <Text variant='h2' className='mb-6'>
        Technicians
      </Text>

      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6 mb-6'>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Workload Distribution</h2>
          <p className='text-sm text-text-secondary mb-4'>Current capacity utilization</p>
          <PieChart data={workloadData} className='h-48' />
        </Card>

        <Card className='lg:col-span-2'>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Team Performance</h2>
          <p className='text-sm text-text-secondary mb-4'>Daily metrics and efficiency</p>
          <div className='grid grid-cols-3 gap-4'>
            <div>
              <div className='text-text-secondary text-sm'>Total Active</div>
              <div className='text-2xl font-semibold text-text-primary'>8</div>
            </div>
            <div>
              <div className='text-text-secondary text-sm'>Avg Efficiency</div>
              <div className='text-2xl font-semibold text-text-primary'>94%</div>
            </div>
            <div>
              <div className='text-text-secondary text-sm'>Total Repairs</div>
              <div className='text-2xl font-semibold text-text-primary'>16</div>
            </div>
          </div>
        </Card>
      </div>

      <Card>
        <div className='flex justify-between items-center mb-4'>
          <div>
            <h2 className='text-lg font-semibold text-text-primary'>Technician List</h2>
            <p className='text-sm text-text-secondary'>Manage repair team and assignments</p>
          </div>
          <button className='px-4 py-2 bg-accent-purple text-text-primary rounded hover:opacity-90'>
            Add Technician
          </button>
        </div>

        <div className='overflow-x-auto'>
          <table className='w-full'>
            <thead>
              <tr className='border-b border-border-base'>
                <th className='text-left py-3 text-text-secondary font-medium'>Name</th>
                <th className='text-left py-3 text-text-secondary font-medium'>Specialties</th>
                <th className='text-left py-3 text-text-secondary font-medium'>Status</th>
                <th className='text-left py-3 text-text-secondary font-medium'>Current Load</th>
                <th className='text-left py-3 text-text-secondary font-medium'>Efficiency</th>
                <th className='text-left py-3 text-text-secondary font-medium'>Completed Today</th>
                <th className='text-left py-3 text-text-secondary font-medium'></th>
              </tr>
            </thead>
            <tbody>
              {technicians.map(tech => (
                <tr key={tech.id} className='border-b border-border-base'>
                  <td className='py-3 text-text-primary'>{tech.name}</td>
                  <td className='py-3'>
                    <div className='flex flex-wrap gap-1'>
                      {tech.specialties.map(specialty => (
                        <span
                          key={specialty}
                          className='px-2 py-1 text-xs rounded bg-bg-panel text-text-secondary'
                        >
                          {specialty}
                        </span>
                      ))}
                    </div>
                  </td>
                  <td className='py-3'>
                    <span
                      className={`px-2 py-1 rounded text-sm ${
                        tech.availability === 'Available'
                          ? 'bg-green-900/20 text-green-500'
                          : tech.availability === 'Working'
                          ? 'bg-blue-900/20 text-blue-500'
                          : 'bg-yellow-900/20 text-yellow-500'
                      }`}
                    >
                      {tech.availability}
                    </span>
                  </td>
                  <td className='py-3 text-text-primary'>{tech.currentLoad} repairs</td>
                  <td className='py-3 text-text-primary'>{tech.efficiency}%</td>
                  <td className='py-3 text-text-primary'>{tech.completedToday}</td>
                  <td className='py-3 text-right'>
                    <button className='text-text-secondary hover:text-text-primary'>
                      View Details
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Card>
    </div>
  )
}
