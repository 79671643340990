import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Text } from '@/shared/components/ui/text'
import { LineChart, BarChart, PieChart } from '@/shared/components/ui/charts'

export function RepairAnalyticsPage() {
  return (
    <div className='space-y-8'>
      <Text variant='h2' className='mb-6'>
        Analytics
      </Text>

      {/* Time-based metrics */}
      <div className='grid grid-cols-1 lg:grid-cols-4 gap-4'>
        <Card>
          <div className='metric-card-title'>Monthly Repairs</div>
          <div className='metric-card-value'>
            248 <span className='trend-positive'>+18%</span>
          </div>
        </Card>
        <Card>
          <div className='metric-card-title'>Avg Processing Time</div>
          <div className='metric-card-value'>
            2.8h <span className='trend-negative'>+0.3h</span>
          </div>
        </Card>
        <Card>
          <div className='metric-card-title'>Customer Satisfaction</div>
          <div className='metric-card-value'>
            4.8 <span className='trend-positive'>+0.2</span>
          </div>
        </Card>
        <Card>
          <div className='metric-card-title'>Revenue</div>
          <div className='metric-card-value'>
            €15.2k <span className='trend-positive'>+22%</span>
          </div>
        </Card>
      </div>

      {/* Trend Charts */}
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Repair Volume Trends</h2>
          <p className='text-sm text-text-secondary mb-4'>Monthly repair requests vs completions</p>
          <LineChart
            data={[
              { timestamp: 1701388800000, value: 180 },
              { timestamp: 1701475200000, value: 220 },
              { timestamp: 1701561600000, value: 240 },
              { timestamp: 1701648000000, value: 280 },
              { timestamp: 1701734400000, value: 248 },
            ]}
            className='h-64'
          />
        </Card>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>
            Service Type Distribution
          </h2>
          <p className='text-sm text-text-secondary mb-4'>Breakdown by repair category</p>
          <PieChart
            data={[
              { label: 'Sole Replacement', value: 35 },
              { label: 'Stitching', value: 25 },
              { label: 'Insole', value: 20 },
              { label: 'Other', value: 20 },
            ]}
            className='h-64'
          />
        </Card>
      </div>

      {/* Detailed Analytics */}
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Customer Returns</h2>
          <p className='text-sm text-text-secondary mb-4'>Post-repair issues by type</p>
          <BarChart
            data={[
              { label: 'Fit Issues', value: 5 },
              { label: 'Quality', value: 3 },
              { label: 'Durability', value: 2 },
              { label: 'Other', value: 1 },
            ]}
            className='h-48'
          />
        </Card>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Processing Time</h2>
          <p className='text-sm text-text-secondary mb-4'>Average time by repair type</p>
          <BarChart
            data={[
              { label: 'Sole', value: 3.2 },
              { label: 'Stitch', value: 1.8 },
              { label: 'Insole', value: 1.2 },
              { label: 'Other', value: 2.4 },
            ]}
            className='h-48'
          />
        </Card>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Customer Feedback</h2>
          <p className='text-sm text-text-secondary mb-4'>Rating distribution</p>
          <BarChart
            data={[
              { label: '5 ★', value: 145 },
              { label: '4 ★', value: 82 },
              { label: '3 ★', value: 18 },
              { label: '2 ★', value: 3 },
              { label: '1 ★', value: 0 },
            ]}
            className='h-48'
          />
        </Card>
      </div>

      {/* Insights Section */}
      <Card>
        <h2 className='text-lg font-semibold mb-4 text-text-primary'>Key Insights</h2>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6'>
          <div>
            <h3 className='text-sm font-medium text-text-secondary mb-2'>Trending Up</h3>
            <ul className='space-y-2'>
              <li className='flex items-center text-text-primary'>
                <span className='w-2 h-2 bg-trend-positive rounded-full mr-2'></span>
                Customer satisfaction increased by 4.2%
              </li>
              <li className='flex items-center text-text-primary'>
                <span className='w-2 h-2 bg-trend-positive rounded-full mr-2'></span>
                Revenue per repair up by €12
              </li>
            </ul>
          </div>
          <div>
            <h3 className='text-sm font-medium text-text-secondary mb-2'>Areas for Improvement</h3>
            <ul className='space-y-2'>
              <li className='flex items-center text-text-primary'>
                <span className='w-2 h-2 bg-trend-negative rounded-full mr-2'></span>
                Processing time increased for sole repairs
              </li>
              <li className='flex items-center text-text-primary'>
                <span className='w-2 h-2 bg-trend-negative rounded-full mr-2'></span>
                Slight increase in quality-related returns
              </li>
            </ul>
          </div>
          <div>
            <h3 className='text-sm font-medium text-text-secondary mb-2'>Recommendations</h3>
            <ul className='space-y-2'>
              <li className='flex items-center text-text-primary'>
                <span className='w-2 h-2 bg-accent-purple rounded-full mr-2'></span>
                Optimize sole repair workflow
              </li>
              <li className='flex items-center text-text-primary'>
                <span className='w-2 h-2 bg-accent-purple rounded-full mr-2'></span>
                Schedule additional quality training
              </li>
            </ul>
          </div>
        </div>
      </Card>
    </div>
  )
}
