import React from 'react'
import { NavLink } from 'react-router-dom'
import { Share2, Download, Filter } from 'lucide-react'
import { Card } from '@/shared/components/ui/card'

interface RefillBox {
  id: string
  facility: string
  dateReceived: string
  status: string
  weight: number
}

const mockBoxes: RefillBox[] = [
  {
    id: 'LRPLY00545897',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:43',
    status: 'In Transit',
    weight: 0.43,
  },
  {
    id: 'LRPLY00354861',
    facility: 'CAON015\nOntario, United States',
    dateReceived: '10 Oct 23 at 10:42',
    status: 'Received',
    weight: 0.85,
  },
  {
    id: 'LRPLY00354862',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:41',
    status: 'Processing',
    weight: 0.62,
  },
  {
    id: 'LRPLY00354863',
    facility: 'CAON015\nOntario, United States',
    dateReceived: '10 Oct 23 at 10:40',
    status: 'Completed',
    weight: 0.91,
  },
  {
    id: 'LRPLY00354864',
    facility: 'USCA005\nCalifornia, United States',
    dateReceived: '10 Oct 23 at 10:39',
    status: 'Received',
    weight: 0.73,
  },
]

export const RefillReturnInformationPage: React.FC = () => {
  return (
    <div>
      <header className='flex items-center justify-between mb-6'>
        <h2 className='text-xl font-semibold text-text-primary'>Return Information</h2>
        <div className='flex gap-2'>
          <button className='flex items-center gap-1 bg-bg-panel text-text-primary py-2 px-3 rounded hover:bg-bg-hover border border-border-base'>
            <Share2 size={16} />
            Share
          </button>
          <button className='flex items-center gap-1 bg-bg-panel text-text-primary py-2 px-3 rounded hover:bg-bg-hover border border-border-base'>
            <Download size={16} />
            Download
          </button>
          <button className='flex items-center gap-1 bg-bg-panel text-text-primary py-2 px-3 rounded hover:bg-bg-hover border border-border-base'>
            <Filter size={16} />
            Filters
          </button>
        </div>
      </header>

      <Card>
        <table className='w-full text-sm'>
          <thead className='border-b border-border-base text-text-secondary'>
            <tr>
              <th className='py-3 text-left px-4'>BOX ID</th>
              <th className='py-3 text-left px-4'>FACILITY</th>
              <th className='py-3 text-left px-4'>DATE RECEIVED</th>
              <th className='py-3 text-left px-4'>STATUS</th>
              <th className='py-3 text-left px-4'>WEIGHT (KGS)</th>
              <th className='py-3 px-4'></th>
            </tr>
          </thead>
          <tbody>
            {mockBoxes.map((box, i) => (
              <tr
                key={i}
                className='border-b border-border-base hover:bg-bg-hover transition-colors'
              >
                <td className='py-3 px-4'>{box.id}</td>
                <td className='py-3 px-4 whitespace-pre'>{box.facility}</td>
                <td className='py-3 px-4'>{box.dateReceived}</td>
                <td className='py-3 px-4'>
                  <span className='bg-bg-panel text-text-primary py-1 px-2 rounded'>
                    {box.status}
                  </span>
                </td>
                <td className='py-3 px-4'>{box.weight.toFixed(2)}</td>
                <td className='py-3 px-4 text-right'>
                  <NavLink
                    to={`/admin/refills/return-information/${box.id}`}
                    className='text-text-secondary hover:text-text-primary flex items-center gap-1'
                  >
                    Details
                  </NavLink>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>

      <div className='flex items-center justify-between mt-4 text-text-secondary'>
        <div>Page 1 of 13</div>
        <div className='flex items-center gap-2'>
          <button className='bg-bg-panel px-3 py-1 rounded border border-border-base hover:bg-bg-hover'>
            &lt;
          </button>
          <button className='bg-bg-panel px-3 py-1 rounded border border-border-base hover:bg-bg-hover'>
            &gt;
          </button>
        </div>
      </div>
    </div>
  )
}
