import React from 'react'
import { LineChart, BarChart } from '@/shared/components/ui/charts'
import { Grid } from '@/shared/components/ui/grid'

export const RecycleOverviewPage: React.FC = () => {
  return (
    <div className='space-y-6'>
      {/* KPI Metrics */}
      <Grid className='grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4'>
        <div className='card'>
          <div className='metric-card-title'>Total Items</div>
          <div className='metric-card-value'>
            1,234 <span className='trend-positive'>+12%</span>
          </div>
        </div>
        <div className='card'>
          <div className='metric-card-title'>Recycling Rate</div>
          <div className='metric-card-value'>
            78% <span className='trend-positive'>+5%</span>
          </div>
        </div>
        <div className='card'>
          <div className='metric-card-title'>Processing Time</div>
          <div className='metric-card-value'>
            3.2d <span className='trend-negative'>+0.5d</span>
          </div>
        </div>
        <div className='card'>
          <div className='metric-card-title'>Cost Savings</div>
          <div className='metric-card-value'>
            €12.4k <span className='trend-positive'>+18%</span>
          </div>
        </div>
      </Grid>

      {/* Charts */}
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <div className='card'>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Items by Category</h2>
          <p className='text-sm text-text-secondary mb-4'>
            Distribution of recycled items across categories
          </p>
          <BarChart
            data={[
              { label: 'Electronics', value: 450 },
              { label: 'Textiles', value: 380 },
              { label: 'Plastics', value: 320 },
              { label: 'Metals', value: 280 },
              { label: 'Others', value: 150 },
            ]}
            className='h-64'
          />
        </div>
        <div className='card'>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Processing Trend</h2>
          <p className='text-sm text-text-secondary mb-4'>Daily volume of processed items</p>
          <LineChart
            data={[
              { timestamp: 1701388800000, value: 120 },
              { timestamp: 1701475200000, value: 150 },
              { timestamp: 1701561600000, value: 180 },
              { timestamp: 1701648000000, value: 140 },
              { timestamp: 1701734400000, value: 160 },
            ]}
            className='h-64'
          />
        </div>
      </div>

      {/* Processing Status */}
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <div className='card'>
          <h2 className='text-lg font-semibold mb-4 text-text-primary'>Current Processing</h2>
          <div className='space-y-4'>
            <div className='progress-container'>
              <div className='progress-step completed'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Collection</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step completed'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Sorting</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Processing</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Complete</span>
              </div>
            </div>
          </div>
        </div>

        <div className='card'>
          <h2 className='text-lg font-semibold mb-4 text-text-primary'>Recent Activity</h2>
          <div className='space-y-4'>
            <div className='tracking-event first'>
              <div className='circle' />
              <div>
                <div className='title'>Large batch received</div>
                <div className='location'>North Collection Center</div>
                <div className='date'>1 hour ago</div>
              </div>
            </div>
            <div className='tracking-event'>
              <div className='circle' />
              <div>
                <div className='title'>Sorting completed</div>
                <div className='location'>Main Facility</div>
                <div className='date'>3 hours ago</div>
              </div>
            </div>
            <div className='tracking-event'>
              <div className='circle' />
              <div>
                <div className='title'>Processing started</div>
                <div className='location'>Recycling Unit 2</div>
                <div className='date'>5 hours ago</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
