import React from 'react'
import { LineChart, BarChart } from '@/shared/components/ui/charts'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'
import { MetricsOverview } from './components/metrics-overview'

export const DashboardPage: React.FC = () => {
  return (
    <div className='py-6'>
      <div className='flex justify-between items-center mb-6'>
        <h1 className='text-2xl font-semibold text-text-primary'>Dashboard</h1>
        <div className='flex gap-3'>
          <button className='px-4 py-2 bg-bg-panel text-text-primary rounded hover:bg-bg-hover'>
            Last 30 days
          </button>
          <button className='px-4 py-2 bg-bg-panel text-text-primary rounded hover:bg-bg-hover'>
            Region: EU
          </button>
        </div>
      </div>

      {/* KPI Cards */}
      <div className="mb-6">
        <MetricsOverview />
      </div>

      {/* Charts Section */}
      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6'>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Processed Items</h2>
          <p className='text-sm text-text-secondary mb-4'>
            Items processed per day broken down by grading
          </p>
          <BarChart
            data={[
              { label: 'Mon', value: 120 },
              { label: 'Tue', value: 150 },
              { label: 'Wed', value: 180 },
              { label: 'Thu', value: 140 },
              { label: 'Fri', value: 160 },
            ]}
            className='h-64'
          />
        </Card>
        <Card>
          <h2 className='text-lg font-semibold mb-2 text-text-primary'>Revenue Trend</h2>
          <p className='text-sm text-text-secondary mb-4'>
            Daily revenue from recommerce operations
          </p>
          <LineChart
            data={[
              { timestamp: 1701388800000, value: 12000 },
              { timestamp: 1701475200000, value: 15000 },
              { timestamp: 1701561600000, value: 18000 },
              { timestamp: 1701648000000, value: 14000 },
              { timestamp: 1701734400000, value: 16000 },
            ]}
            dataKeys={['value']}
            xAxisKey="timestamp"
            className='h-64'
          />
        </Card>
      </div>

      {/* Bottom Metrics */}
      <Grid className='grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4'>
        <Card>
          <h3 className='text-lg font-semibold mb-4 text-text-primary'>Top Categories</h3>
          <div className='space-y-3'>
            {[
              { name: 'Electronics', value: '32%' },
              { name: 'Apparel', value: '28%' },
              { name: 'Home Goods', value: '24%' },
            ].map(category => (
              <div key={category.name} className='flex justify-between items-center'>
                <span className='text-text-secondary'>{category.name}</span>
                <span className='text-text-primary font-medium'>{category.value}</span>
              </div>
            ))}
          </div>
        </Card>
        <Card>
          <h3 className='text-lg font-semibold mb-4 text-text-primary'>Processing Status</h3>
          <div className='space-y-4'>
            <div className='progress-container'>
              <div className='progress-step completed'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Received</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step completed'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Graded</span>
              </div>
              <div className='progress-connector' />
              <div className='progress-step'>
                <div className='circle' />
                <span className='text-sm text-text-secondary mt-1'>Listed</span>
              </div>
            </div>
          </div>
        </Card>
        <Card>
          <h3 className='text-lg font-semibold mb-4 text-text-primary'>Recent Activity</h3>
          <div className='space-y-4'>
            <div className='tracking-event first'>
              <div className='circle' />
              <div>
                <div className='title'>New batch received</div>
                <div className='location'>Warehouse #3</div>
                <div className='date'>2 hours ago</div>
              </div>
            </div>
            <div className='tracking-event'>
              <div className='circle' />
              <div>
                <div className='title'>Grading completed</div>
                <div className='location'>Processing Center</div>
                <div className='date'>4 hours ago</div>
              </div>
            </div>
          </div>
        </Card>
      </Grid>
    </div>
  )
}
