import React from 'react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'

export const ResaleEnvironmentPage: React.FC = () => {
  return (
    <div className='space-y-8'>
      <Text variant='h3'>Environment</Text>

      <div className='grid grid-cols-4 gap-6'>
        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Carbon Savings
          </Text>
          <Text variant='h3' className='mt-2'>
            1.23mt
          </Text>
          <Text variant='body' className='text-green-400 text-sm'>
            +5.2% vs last month
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Water Saved
          </Text>
          <Text variant='h3' className='mt-2'>
            2,345L
          </Text>
          <Text variant='body' className='text-green-400 text-sm'>
            +8.7% vs last month
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Materials Recycled
          </Text>
          <Text variant='h3' className='mt-2'>
            456kg
          </Text>
          <Text variant='body' className='text-green-400 text-sm'>
            +12.3% vs last month
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Energy Saved
          </Text>
          <Text variant='h3' className='mt-2'>
            789kWh
          </Text>
          <Text variant='body' className='text-green-400 text-sm'>
            +3.4% vs last month
          </Text>
        </Card>
      </div>

      <div className='grid grid-cols-2 gap-6'>
        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Environmental Impact Trend
          </Text>
          <LineChart
            data={[
              { timestamp: 1701734400000, value: 123 },
              { timestamp: 1701648000000, value: 115 },
              { timestamp: 1701561600000, value: 98 },
              { timestamp: 1701475200000, value: 87 },
              { timestamp: 1701388800000, value: 76 },
            ]}
            className='h-64'
          />
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Impact by Category
          </Text>
          <BarChart
            data={[
              { label: 'Carbon', value: 75 },
              { label: 'Water', value: 80 },
              { label: 'Materials', value: 66 },
              { label: 'Energy', value: 70 },
            ]}
            className='h-64'
          />
        </Card>
      </div>

      <Card className='p-4'>
        <Text variant='label' className='text-neutral-400'>
          Environmental Impact Distribution
        </Text>
        <div className='grid grid-cols-3 gap-6 mt-4'>
          <div>
            <Text variant='body' className='text-neutral-100'>
              Carbon Footprint Reduction
            </Text>
            <div className='w-full h-4 bg-neutral-700 rounded-full mt-2'>
              <div className='w-3/4 h-full bg-green-500 rounded-full'></div>
            </div>
            <Text variant='body' className='text-neutral-400 text-sm mt-1'>
              75% of target
            </Text>
          </div>
          <div>
            <Text variant='body' className='text-neutral-100'>
              Water Conservation
            </Text>
            <div className='w-full h-4 bg-neutral-700 rounded-full mt-2'>
              <div className='w-4/5 h-full bg-blue-500 rounded-full'></div>
            </div>
            <Text variant='body' className='text-neutral-400 text-sm mt-1'>
              80% of target
            </Text>
          </div>
          <div>
            <Text variant='body' className='text-neutral-100'>
              Material Recovery
            </Text>
            <div className='w-full h-4 bg-neutral-700 rounded-full mt-2'>
              <div className='w-2/3 h-full bg-yellow-500 rounded-full'></div>
            </div>
            <Text variant='body' className='text-neutral-400 text-sm mt-1'>
              66% of target
            </Text>
          </div>
        </div>
      </Card>
    </div>
  )
}
