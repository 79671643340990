import React from 'react'
import { cn } from '@/lib/utils'

interface TextProps extends React.HTMLAttributes<HTMLParagraphElement> {
  children: React.ReactNode
  variant?: 'h1' | 'h2' | 'h3' | 'body' | 'label'
}

export function Text({ children, variant = 'body', className, ...props }: TextProps) {
  const variantStyles = {
    h1: 'text-2xl font-bold',
    h2: 'text-xl font-semibold',
    h3: 'text-lg font-medium',
    body: 'text-base',
    label: 'text-sm font-medium text-neutral-600',
  }

  return (
    <p className={cn(variantStyles[variant], className)} {...props}>
      {children}
    </p>
  )
}
