import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import * as Tabs from '@radix-ui/react-tabs'
import { ChevronRight, FileText } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'

interface ResaleDetail {
  id: string
  status: string
  productName: string
  productSKU: string
  size: string
  outcomeOnAssessment: string
  originChannel: string
  facility: string
  timeInRepair: number
  carbonSavings: string
  resalePrice: number
  resalePriceTrend: number
  timeListed: number
  timeListedTrend: number
  repairCost: number
  repairCostTrend: number
  profitMargin: number
  profitMarginTrend: number
  progressSteps: Array<{ label: string; completed: boolean }>
  trackingHistory: Array<{
    title: string
    location: string
    date: string
  }>
}

const mockResaleDetail: ResaleDetail = {
  id: 'LRPLY00645987',
  status: 'Listed',
  productName: 'Cloudmonster 2',
  productSKU: '#2M3ME1012',
  size: 'UK11',
  outcomeOnAssessment: 'Good',
  originChannel: 'Online - Direct from Consumer',
  facility: 'USCA005 • California, United States',
  timeInRepair: 1.45,
  carbonSavings: '0.08mt',
  resalePrice: 40.0,
  resalePriceTrend: 0.1,
  timeListed: 1.45,
  timeListedTrend: 0.25,
  repairCost: 18.0,
  repairCostTrend: 1.82,
  profitMargin: 45,
  profitMarginTrend: 1.37,
  progressSteps: [
    { label: 'Received', completed: true },
    { label: 'Assess', completed: true },
    { label: 'Repair', completed: true },
    { label: 'Quality Check', completed: true },
    { label: 'Listed', completed: true },
    { label: 'Complete', completed: false },
  ],
  trackingHistory: [
    { title: 'Listed', location: '', date: '12 Oct at 10:00' },
    { title: 'Listed for Resale', location: '', date: '12 Oct at 10:00' },
    {
      title: 'Quality Check',
      location: '',
      date: '12 Oct at 09:30',
    },
    {
      title: 'Quality Check Started',
      location: 'USCA005 • California, United States',
      date: '12 Oct at 08:45',
    },
    { title: 'Repair', location: '', date: '11 Oct at 11:54' },
    {
      title: 'Repair Started',
      location: 'USCA005 • California, United States',
      date: '11 Oct at 11:54',
    },
    { title: 'Assessment', location: '', date: '11 Oct at 08:28' },
    {
      title: 'Outcome Logged',
      location: 'USCA005 • California, United States',
      date: '11 Oct at 08:28',
    },
    {
      title: 'Assessment Started',
      location: 'USCA005 • California, United States',
      date: '11 Oct at 08:12',
    },
    {
      title: 'Received',
      location: 'California, United States',
      date: '10 Oct at 16:15',
    },
  ],
}

const DetailRow: React.FC<{ label: string; value: string }> = ({ label, value }) => (
  <div>
    <Text variant='label' className='text-neutral-400'>
      {label}
    </Text>
    <Text variant='body' className='text-neutral-100 mt-1'>
      {value}
    </Text>
  </div>
)

const MetricCard: React.FC<{
  title: string
  value: string
  trend: number
}> = ({ title, value, trend }) => {
  const trendColor = trend > 0 ? 'text-green-400' : 'text-red-400'
  return (
    <Card className='p-4'>
      <Text variant='label' className='text-neutral-400'>
        {title}
      </Text>
      <div className='text-2xl font-bold flex items-baseline gap-2 mt-1'>
        <span className='text-neutral-100'>{value}</span>
        <span className={`text-sm ${trendColor}`}>{trend > 0 ? `+${trend}%` : `${trend}%`}</span>
      </div>
    </Card>
  )
}

export const ResaleDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>()
  const data = id ? mockResaleDetail : mockResaleDetail

  return (
    <div className='text-neutral-100'>
      <nav className='flex items-center text-sm text-neutral-300 mb-4'>
        <NavLink to='/admin/resale' className='hover:text-neutral-100'>
          Resale
        </NavLink>
        <ChevronRight size={16} className='mx-2' />
        <span className='text-neutral-300'>Resale Information</span>
        <ChevronRight size={16} className='mx-2' />
        <span className='text-neutral-100 font-medium'>{data.id}</span>
        <span className='text-sm bg-neutral-700 text-neutral-200 px-2 py-1 rounded ml-2'>
          {data.status}
        </span>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <Text variant='h2'>{data.id}</Text>
      </header>

      <Tabs.Root defaultValue='overview'>
        <Tabs.List className='flex space-x-4 border-b border-neutral-700 mb-6'>
          <Tabs.Trigger
            value='overview'
            className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-neutral-100'
          >
            Overview
          </Tabs.Trigger>
          <Tabs.Trigger
            value='repair'
            className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-neutral-100'
          >
            Repair
          </Tabs.Trigger>
          <Tabs.Trigger
            value='resale'
            className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-neutral-100'
          >
            Resale
          </Tabs.Trigger>
          <Tabs.Trigger
            value='recycle'
            className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-neutral-100'
          >
            Recycle
          </Tabs.Trigger>
          <Tabs.Trigger
            value='documents'
            className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-neutral-100 flex items-center gap-1'
          >
            <FileText size={14} />
            Documents
          </Tabs.Trigger>
          <Tabs.Trigger
            value='history'
            className='px-3 py-2 text-sm text-neutral-400 data-[state=active]:border-b-2 data-[state=active]:border-blue-500 data-[state=active]:text-neutral-100'
          >
            History
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='overview' className='space-y-8'>
          {/* Progress */}
          <Card className='p-4'>
            <div className='flex items-center'>
              {data.progressSteps.map((step, i) => (
                <React.Fragment key={i}>
                  <div className='flex flex-col items-center'>
                    <div
                      className={`w-6 h-6 rounded-full ${
                        step.completed ? 'bg-green-400' : 'bg-neutral-600'
                      }`}
                    ></div>
                    <Text variant='body' className='text-neutral-400 text-sm mt-2'>
                      {step.label}
                    </Text>
                  </div>
                  {i < data.progressSteps.length - 1 && (
                    <div className='flex-1 border-t border-neutral-600 mx-2'></div>
                  )}
                </React.Fragment>
              ))}
            </div>
          </Card>

          <div className='grid grid-cols-3 gap-8'>
            {/* Overview */}
            <Card className='p-4 space-y-4'>
              <Text variant='h3'>Overview</Text>
              <DetailRow
                label='Product'
                value={`${data.productName} • Size ${data.size} • ${data.productSKU}`}
              />
              <DetailRow label='SKU' value={data.productSKU} />
              <DetailRow label='Size' value={data.size} />
              <DetailRow label='Outcome on Assessment' value={data.outcomeOnAssessment} />
              <DetailRow label='Origin Channel' value={data.originChannel} />
              <DetailRow label='Current facility' value={data.facility} />
              <DetailRow label='Time in Repair (Days)' value={String(data.timeInRepair)} />
              <DetailRow label='Carbon Savings' value={data.carbonSavings} />
            </Card>

            {/* Metrics */}
            <div className='grid grid-cols-2 gap-4'>
              <MetricCard
                title='Resale Price'
                value={`$${data.resalePrice.toFixed(2)}`}
                trend={data.resalePriceTrend}
              />
              <MetricCard
                title='Time Listed'
                value={`${data.timeListed} days`}
                trend={data.timeListedTrend}
              />
              <MetricCard
                title='Repair Cost'
                value={`$${data.repairCost.toFixed(2)}`}
                trend={data.repairCostTrend}
              />
              <MetricCard
                title='Profit Margin'
                value={`${data.profitMargin}%`}
                trend={data.profitMarginTrend}
              />
            </div>

            {/* Tracking History */}
            <Card className='p-4'>
              <Text variant='h3' className='mb-4'>
                Tracking History
              </Text>
              <div className='flex flex-col space-y-4'>
                {data.trackingHistory.map((event, i) => (
                  <div key={i} className='flex items-start gap-4'>
                    <div className='flex flex-col items-center'>
                      <div
                        className={`w-4 h-4 rounded-full ${
                          i === 0 ? 'bg-green-400' : 'bg-neutral-600'
                        } border-2 border-neutral-700`}
                      ></div>
                    </div>
                    <div className='text-sm'>
                      <Text variant='body' className='text-neutral-100'>
                        {event.title}
                      </Text>
                      {event.location && (
                        <Text variant='body' className='text-neutral-400 text-sm'>
                          {event.location}
                        </Text>
                      )}
                      <Text variant='body' className='text-neutral-400 text-sm'>
                        {event.date}
                      </Text>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </div>
        </Tabs.Content>

        {/* Other tabs will be implemented as needed */}
      </Tabs.Root>
    </div>
  )
}
