import React, { useEffect, useState } from 'react'
import { BarChart3, Brain, Database, GitBranch } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'
import { useLearningSystem } from '@/domain/ai/learning/hooks/useLearningSystem'
import { NavLink } from 'react-router-dom'

interface LearningMetric {
  title: string
  value: string
  change: string
  icon: React.ReactNode
}

interface LearningEvent {
  id: string
  type: string
  message: string
  confidence: number
  timestamp: number
}

interface LearningStream {
  id: string
  name: string
  status: string
  eventCount: number
}

export function AILearningPage() {
  const { metrics } = useLearningSystem()
  const [learningEvents, setLearningEvents] = useState<LearningEvent[]>([])
  const [activeStreams, setActiveStreams] = useState<LearningStream[]>([])

  useEffect(() => {
    // Simulated learning events for demonstration
    setLearningEvents([
      {
        id: '1',
        type: 'classification',
        message: 'Product Classification Update',
        confidence: 0.968,
        timestamp: Date.now() - 120000,
      },
      {
        id: '2',
        type: 'pattern',
        message: 'Shopping Pattern Detected',
        confidence: 0.945,
        timestamp: Date.now() - 180000,
      },
      {
        id: '3',
        type: 'recommendation',
        message: 'Recommendation Model Updated',
        confidence: 0.932,
        timestamp: Date.now() - 240000,
      },
    ])

    // Simulated active streams for demonstration
    setActiveStreams([
      {
        id: 'stream1',
        name: 'Product Return Analysis',
        status: 'Active',
        eventCount: 4892,
      },
      {
        id: 'stream2',
        name: 'Shopping Pattern Analysis',
        status: 'Active',
        eventCount: 3567,
      },
      {
        id: 'stream3',
        name: 'Customer Behavior Analysis',
        status: 'Active',
        eventCount: 2845,
      },
    ])
  }, [])

  const currentMetrics: LearningMetric[] = [
    {
      title: 'Total Learning Events',
      value: '124,892',
      change: '+12.3%',
      icon: <Database className='w-5 h-5' />,
    },
    {
      title: 'Model Accuracy',
      value: `${(metrics.accuracy * 100).toFixed(1)}%`,
      change: '+2.1%',
      icon: <Brain className='w-5 h-5' />,
    },
    {
      title: 'Active Learning Streams',
      value: String(activeStreams.length),
      change: '+1',
      icon: <GitBranch className='w-5 h-5' />,
    },
    {
      title: 'Success Rate',
      value: `${(metrics.successRate * 100).toFixed(1)}%`,
      change: '+5.2%',
      icon: <BarChart3 className='w-5 h-5' />,
    },
  ]

  const formatTimeAgo = (timestamp: number) => {
    const seconds = Math.floor((Date.now() - timestamp) / 1000)
    if (seconds < 60) return `${seconds} seconds ago`
    const minutes = Math.floor(seconds / 60)
    return `${minutes} mins ago`
  }

  return (
    <div className='text-text-primary'>
      <nav className='flex items-center text-sm text-text-secondary mb-4'>
        <Text variant='label' className='font-medium'>
          AI Learning
        </Text>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <Text variant='h1'>AI Learning Dashboard</Text>
        <div className='flex gap-2'>
          <button className='px-4 py-2 bg-bg-panel text-text-primary rounded hover:bg-bg-hover'>
            Last 24 hours
          </button>
          <button className='px-4 py-2 bg-accent-purple text-text-primary rounded hover:opacity-90'>
            Configure
          </button>
        </div>
      </header>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8'>
        {currentMetrics.map((metric, index) => (
          <div
            key={index}
            className='bg-bg-nav p-6 rounded-lg shadow hover:shadow-md transition-shadow'
          >
            <div className='flex items-center justify-between mb-4'>
              <div className='p-2 bg-bg-hover rounded-full'>{metric.icon}</div>
              <span
                className={`text-sm ${
                  metric.change.startsWith('+') ? 'text-trend-positive' : 'text-trend-negative'
                }`}
              >
                {metric.change}
              </span>
            </div>
            <h3 className='text-text-secondary text-sm mb-1'>{metric.title}</h3>
            <p className='text-2xl font-semibold'>{metric.value}</p>
          </div>
        ))}
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <div className='bg-bg-nav p-6 rounded-lg shadow'>
          <h2 className='text-lg font-semibold mb-4'>Recent Learning Events</h2>
          <div className='space-y-4'>
            {learningEvents.map(event => (
              <div
                key={event.id}
                className='flex items-center justify-between py-2 border-b border-border-base last:border-0'
              >
                <div>
                  <p className='text-text-primary'>{event.message}</p>
                  <p className='text-sm text-text-secondary'>
                    Confidence: {(event.confidence * 100).toFixed(1)}%
                  </p>
                </div>
                <span className='text-sm text-text-secondary'>
                  {formatTimeAgo(event.timestamp)}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className='bg-bg-nav p-6 rounded-lg shadow'>
          <h2 className='text-lg font-semibold mb-4'>Active Learning Streams</h2>
          <div className='space-y-4'>
            {activeStreams.map(stream => (
              <NavLink
                key={stream.id}
                to={`/admin/ai-learning/stream/${stream.id}`}
                className='flex items-center justify-between py-2 border-b border-border-base last:border-0 hover:bg-bg-hover px-2 rounded transition-colors'
              >
                <div>
                  <p className='text-text-primary'>{stream.name}</p>
                  <p className='text-sm text-text-secondary'>Status: {stream.status}</p>
                </div>
                <span className='text-sm text-text-secondary'>
                  {stream.eventCount.toLocaleString()} events
                </span>
              </NavLink>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
