import React from 'react'
import {
  ResponsiveContainer,
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from 'recharts'

interface LineChartProps {
  data: any[]
  dataKeys: string[]
  xAxisKey: string
  height?: number
  colors?: string[]
  className?: string
}

// Add date formatter helper
const formatDate = (timestamp: number) => {
  const date = new Date(timestamp)
  return date.toLocaleDateString('en-US', { 
    month: 'short',
    day: '2-digit'
  })
}

export const LineChart: React.FC<LineChartProps> = ({
  data = [],
  dataKeys = [],
  xAxisKey,
  height = 300,
  colors = ['#8b5cf6', '#8b5cf6', '#f59e0b'],
  className
}) => {
  if (!data?.length || !dataKeys?.length) {
    return null
  }

  return (
    <div className={className}>
      <ResponsiveContainer width="100%" height="100%">
        <RechartsLineChart data={data} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <XAxis 
            dataKey={xAxisKey}
            tickFormatter={formatDate}
            stroke="#FFFFFF"
            tick={{ fill: '#FFFFFF', fontSize: 12 }}
          />
          <YAxis 
            stroke="#FFFFFF"
            tick={{ fill: '#FFFFFF', fontSize: 12 }}
          />
          <Tooltip 
            labelFormatter={formatDate}
            contentStyle={{ backgroundColor: '#1F2937', border: 'none' }}
            labelStyle={{ color: '#FFFFFF' }}
          />
          <Legend />
          {dataKeys.map((key, index) => (
            <Line
              key={key}
              type="monotone"
              dataKey={key}
              stroke={colors[index % colors.length]}
              activeDot={{ r: 8 }}
            />
          ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </div>
  )
}
