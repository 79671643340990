import React from 'react'
import { NavLink, Outlet, useLocation } from 'react-router-dom'
import * as Tabs from '@radix-ui/react-tabs'
import { Text } from '@/shared/components/ui/text'

export const ResaleProgramPage: React.FC = () => {
  const location = useLocation()
  let currentTab = 'overview'
  if (location.pathname.includes('financials')) currentTab = 'financials'
  if (location.pathname.includes('environment')) currentTab = 'environment'
  if (location.pathname.includes('resale-information')) currentTab = 'resale-information'

  return (
    <div className='text-neutral-100'>
      <nav className='flex items-center text-sm text-neutral-400 mb-4'>
        <NavLink to='/admin/resale' className='hover:text-neutral-100'>
          Resale
        </NavLink>
      </nav>
      <header className='flex items-center justify-between mb-6'>
        <Text variant='h2'>Resale</Text>
      </header>

      <Tabs.Root defaultValue={currentTab} value={currentTab}>
        <Tabs.List className='flex space-x-4 border-b border-neutral-700 mb-6'>
          <NavLink
            to='/admin/resale/overview'
            className={({ isActive }) =>
              `px-3 py-2 text-sm ${
                isActive
                  ? 'border-b-2 border-blue-500 text-neutral-100'
                  : 'text-neutral-400 hover:text-neutral-100'
              }`
            }
          >
            Overview
          </NavLink>
          <NavLink
            to='/admin/resale/financials'
            className={({ isActive }) =>
              `px-3 py-2 text-sm ${
                isActive
                  ? 'border-b-2 border-blue-500 text-neutral-100'
                  : 'text-neutral-400 hover:text-neutral-100'
              }`
            }
          >
            Financials
          </NavLink>
          <NavLink
            to='/admin/resale/environment'
            className={({ isActive }) =>
              `px-3 py-2 text-sm ${
                isActive
                  ? 'border-b-2 border-blue-500 text-neutral-100'
                  : 'text-neutral-400 hover:text-neutral-100'
              }`
            }
          >
            Environment
          </NavLink>
          <NavLink
            to='/admin/resale/resale-information'
            className={({ isActive }) =>
              `px-3 py-2 text-sm ${
                isActive
                  ? 'border-b-2 border-blue-500 text-neutral-100'
                  : 'text-neutral-400 hover:text-neutral-100'
              }`
            }
          >
            Resale Information
          </NavLink>
        </Tabs.List>

        <Outlet />
      </Tabs.Root>
    </div>
  )
}
