import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Brain, Settings, Activity, AlertCircle } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'
import { useLearningSystem } from '@/domain/ai/learning/hooks/useLearningSystem'
import { useSimulatedAIEvents } from './useSimulatedAIEvents'
import { ModelUpdate, StreamMetric } from '@/admin/api/ai-learning/types'
import { mockModelUpdates } from '@/admin/api/ai-learning/mock-data'

export function AILearningStreamDetail() {
  const { streamId } = useParams()
  const { metrics } = useLearningSystem()
  const { events: streamEvents, isLoading } = useSimulatedAIEvents()

  const [modelUpdates, setModelUpdates] = useState<ModelUpdate[]>([])

  useEffect(() => {
    setModelUpdates(mockModelUpdates)
  }, [streamId])

  const streamMetrics: StreamMetric[] = [
    { label: 'Accuracy', value: '96.8%', change: '+2.3%' },
    { label: 'Events Today', value: '1,234' },
    {
      label: 'Average Confidence',
      value: `${(metrics.accuracy * 100).toFixed(1)}%`,
      change: '+1.1%',
    },
    {
      label: 'Error Rate',
      value: `${(100 - metrics.successRate * 100).toFixed(1)}%`,
      change: '-0.3%',
    },
  ]

  const formatTimeAgo = (timestamp: number) => {
    const seconds = Math.floor((Date.now() - timestamp) / 1000)
    if (seconds < 60) return `${seconds} seconds ago`
    if (seconds < 3600) return `${Math.floor(seconds / 60)} minutes ago`
    if (seconds < 86400) return `${Math.floor(seconds / 3600)} hours ago`
    return `${Math.floor(seconds / 86400)} days ago`
  }

  return (
    <div className='text-text-primary'>
      <nav className='flex items-center text-sm text-text-secondary mb-4'>
        <Text variant='label' className='font-medium'>
          AI Learning / Stream Details
        </Text>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <div>
          <Text variant='h1'>Product Return Analysis Stream</Text>
          <Text variant='body' className='text-text-secondary mt-1'>
            Stream ID: {streamId}
          </Text>
        </div>
        <button className='flex items-center gap-2 bg-bg-panel py-2 px-4 rounded hover:bg-bg-hover'>
          <Settings size={16} />
          <span>Configure</span>
        </button>
      </header>

      <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8'>
        {streamMetrics.map((metric, index) => (
          <div key={index} className='bg-bg-panel p-6 rounded-lg shadow'>
            <h3 className='text-text-secondary text-sm mb-2'>{metric.label}</h3>
            <div className='flex items-center justify-between'>
              <p className='text-2xl font-semibold'>{metric.value}</p>
              {metric.change && (
                <span
                  className={`text-sm ${
                    metric.change.startsWith('+') ? 'text-trend-positive' : 'text-trend-negative'
                  }`}
                >
                  {metric.change}
                </span>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
        <div className='bg-bg-panel p-6 rounded-lg shadow'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-lg font-semibold'>Recent Events</h2>
            <div className='flex items-center gap-2'>
              <span className='text-xs text-green-500'>LIVE</span>
              <Activity className='w-5 h-5 text-text-secondary' />
            </div>
          </div>
          <div className='space-y-4 max-h-[500px] overflow-y-auto'>
            {isLoading && (
              <div className='flex items-center justify-between py-2 border-b border-border-base animate-pulse'>
                <div>
                  <div className='h-4 w-64 bg-gray-200 rounded'></div>
                  <div className='h-3 w-32 bg-gray-200 rounded mt-2'></div>
                </div>
                <div className='h-3 w-24 bg-gray-200 rounded'></div>
              </div>
            )}
            {streamEvents.map(event => (
              <div
                key={event.id}
                className='flex items-center justify-between py-2 border-b border-border-base last:border-0'
              >
                <div>
                  <p className='text-text-primary'>{event.message}</p>
                  <p className='text-sm text-text-secondary'>
                    Confidence: {(event.confidence * 100).toFixed(1)}%
                  </p>
                </div>
                <span className='text-sm text-text-secondary'>
                  {formatTimeAgo(event.timestamp)}
                </span>
              </div>
            ))}
          </div>
        </div>

        <div className='bg-bg-panel p-6 rounded-lg shadow'>
          <div className='flex items-center justify-between mb-4'>
            <h2 className='text-lg font-semibold'>Model Performance</h2>
            <Brain className='w-5 h-5 text-text-secondary' />
          </div>
          <div className='space-y-4'>
            <div className='flex items-center justify-between py-2 border-b border-border-base'>
              <div>
                <p className='text-text-primary'>Training Status</p>
                <p className='text-sm text-text-secondary'>Last updated 5 mins ago</p>
              </div>
              <span className='px-2 py-1 bg-green-100 text-green-800 rounded text-sm'>Active</span>
            </div>
            {modelUpdates.map((update, index) => (
              <div
                key={index}
                className='flex items-center justify-between py-2 border-b border-border-base'
              >
                <div>
                  <p className='text-text-primary'>Model Version</p>
                  <p className='text-sm text-text-secondary'>{update.version}</p>
                  <div className='mt-1 space-y-1'>
                    {update.changes.map((change, i) => (
                      <p key={i} className='text-xs text-text-secondary'>
                        • {change}
                      </p>
                    ))}
                  </div>
                </div>
                <span className='text-sm text-text-secondary'>
                  Updated {formatTimeAgo(update.timestamp)}
                </span>
              </div>
            ))}
            <div className='flex items-center justify-between py-2'>
              <div className='flex items-center gap-2'>
                <AlertCircle className='w-4 h-4 text-yellow-500' />
                <p className='text-text-primary'>Performance Monitoring Active</p>
              </div>
              <button className='text-sm text-blue-500 hover:underline'>View Details</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
