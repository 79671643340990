import React from 'react'
import { Bar } from 'react-chartjs-2'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface BarData {
  label: string
  value: number
}

interface BarChartProps {
  data: BarData[]
  className?: string
  height?: number
  yLabel?: string
}

export const BarChart: React.FC<BarChartProps> = ({
  data,
  className,
  height = 300,
  yLabel = '',
}) => {
  const chartData = {
    labels: data.map(d => d.label),
    datasets: [
      {
        label: yLabel,
        data: data.map(d => d.value),
        // backgroundColor: ['#8b5cf6', '#10b981', '#f59e0b', '#3b82f6', '#ef4444', '#ec4899'],
        backgroundColor: ['#04cfa8', '#6b8ee7', '#893aad', '#c22960', '#e4c066', '#ec4899'],
        borderWidth: 0,
        borderRadius: 4,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        mode: 'index' as const,
        intersect: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: '#94a3b8',
        },
      },
      y: {
        grid: {
          color: '#334155',
        },
        ticks: {
          color: '#94a3b8',
        },
      },
    },
  }

  return (
    <div className={className} style={{ height }}>
      <Bar data={chartData} options={options} />
    </div>
  )
}
