import React from 'react'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'
import { Text } from '@/shared/components/ui/text'
import { Metric } from '@/shared/components/ui/metric'
import { LineChart } from '@/shared/components/ui/charts/line-chart'
import type { RefillInventoryStats, RefillStationStatus } from './types'

const mockInventoryStats: RefillInventoryStats[] = [
  {
    productId: '1',
    product: {
      id: '1',
      name: 'Eco Shampoo',
      description: 'Natural shampoo in refillable container',
      price: 12.99,
      imageUrl: '/images/shampoo.jpg',
      tags: ['eco-friendly', 'refillable'],
      refillDiscount: 0.2,
      isRefillable: true,
      minimumRefillInterval: 14,
      recommendedRefillInterval: 30,
      availableSizes: ['250ml', '500ml'],
      inStoreAvailability: true,
      sustainabilityScore: 9.5,
      createdAt: new Date().toISOString(),
      isActive: true,
    },
    inStoreStock: 150,
    reservedStock: 30,
    reorderPoint: 100,
    averageDailyDemand: 15,
    nextDeliveryDate: '2023-12-15',
  },
]

const mockStationStatus: RefillStationStatus[] = [
  {
    stationId: 'STATION-001',
    isOperational: true,
    currentOrder: {
      id: 'ORDER-123',
      productId: '1',
      quantity: 2,
    },
    queueLength: 3,
    lastMaintenance: '2023-12-01',
    nextMaintenance: '2023-12-15',
  },
]

export function RefillPackagingFlowsPage() {
  return (
    <div className='space-y-8'>
      <Text variant='h2' className='mb-6 text-text-primary'>
        Packaging Flows
      </Text>

      <Grid columns={2} gap={6}>
        {/* Inventory Status */}
        <Card>
          <Text variant='h3' className='mb-4 text-text-primary'>
            Inventory Status
          </Text>
          <div className='space-y-4'>
            {mockInventoryStats.map(item => (
              <div key={item.productId} className='border-b border-border-primary pb-4'>
                <div className='flex justify-between items-start mb-2'>
                  <div>
                    <Text className='font-medium text-text-primary'>{item.product.name}</Text>
                    <Text className='text-sm text-text-secondary'>ID: {item.productId}</Text>
                  </div>
                  <div className='text-right'>
                    <Text className='font-medium text-text-primary'>{item.inStoreStock} units</Text>
                    <Text className='text-sm text-text-secondary'>In stock</Text>
                  </div>
                </div>
                <Grid columns={3} gap={4}>
                  <div>
                    <Text className='text-sm text-text-secondary'>Reserved</Text>
                    <Text className='text-text-primary'>{item.reservedStock} units</Text>
                  </div>
                  <div>
                    <Text className='text-sm text-text-secondary'>Reorder Point</Text>
                    <Text className='text-text-primary'>{item.reorderPoint} units</Text>
                  </div>
                  <div>
                    <Text className='text-sm text-text-secondary'>Daily Demand</Text>
                    <Text className='text-text-primary'>{item.averageDailyDemand} units</Text>
                  </div>
                </Grid>
                <Text className='text-sm text-text-secondary mt-2'>
                  Next delivery: {item.nextDeliveryDate}
                </Text>
              </div>
            ))}
          </div>
        </Card>

        {/* Refill Station Status */}
        <Card>
          <Text variant='h3' className='mb-4 text-text-primary'>
            Refill Station Status
          </Text>
          <div className='space-y-4'>
            {mockStationStatus.map(station => (
              <div key={station.stationId} className='border-b border-border-primary pb-4'>
                <div className='flex justify-between items-start mb-2'>
                  <div>
                    <Text className='font-medium text-text-primary'>
                      Station {station.stationId}
                    </Text>
                    <Text
                      className={`text-sm ${
                        station.isOperational ? 'text-status-success' : 'text-status-error'
                      }`}
                    >
                      {station.isOperational ? 'Operational' : 'Down'}
                    </Text>
                  </div>
                  <div className='text-right'>
                    <Text className='font-medium text-text-primary'>{station.queueLength}</Text>
                    <Text className='text-sm text-text-secondary'>In queue</Text>
                  </div>
                </div>
                {station.currentOrder && (
                  <div className='bg-background-tertiary p-3 rounded-md mb-2'>
                    <Text className='text-sm font-medium text-text-primary'>Current Order</Text>
                    <Text className='text-sm text-text-secondary'>
                      Order ID: {station.currentOrder.id}
                    </Text>
                    <Text className='text-sm text-text-secondary'>
                      Quantity: {station.currentOrder.quantity} units
                    </Text>
                  </div>
                )}
                <Grid columns={2} gap={4}>
                  <div>
                    <Text className='text-sm text-text-secondary'>Last Maintenance</Text>
                    <Text className='text-text-primary'>{station.lastMaintenance}</Text>
                  </div>
                  <div>
                    <Text className='text-sm text-text-secondary'>Next Maintenance</Text>
                    <Text className='text-text-primary'>{station.nextMaintenance}</Text>
                  </div>
                </Grid>
              </div>
            ))}
          </div>
        </Card>

        {/* Flow Metrics */}
        <Card className='col-span-2'>
          <Text variant='h3' className='mb-4 text-text-primary'>
            Flow Metrics
          </Text>
          <Grid columns={4} gap={4}>
            <div>
              <Text className='text-sm text-text-secondary'>Processing Rate</Text>
              <Metric className='text-text-primary'>45 units/hr</Metric>
            </div>
            <div>
              <Text className='text-sm text-text-secondary'>Average Wait Time</Text>
              <Metric className='text-text-primary'>5.2 mins</Metric>
            </div>
            <div>
              <Text className='text-sm text-text-secondary'>Efficiency Rate</Text>
              <Metric className='text-text-primary'>94%</Metric>
            </div>
            <div>
              <Text className='text-sm text-text-secondary'>Error Rate</Text>
              <Metric className='text-text-primary'>0.5%</Metric>
            </div>
          </Grid>
        </Card>
      </Grid>
    </div>
  )
}
