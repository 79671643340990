import React from 'react'
import { Pie } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, Legend, ChartData, TooltipItem } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)

interface PieChartProps {
  data: Array<{
    label: string
    value: number
  }>
  colors?: string[]
  className?: string
}

export function PieChart({ data, colors = ['#04cfa8', '#e4c167'], className }: PieChartProps) {
  const chartData: ChartData<'pie'> = {
    labels: data.map(d => d.label),
    datasets: [
      {
        data: data.map(d => d.value),
        backgroundColor: colors,
        borderColor: colors.map(() => '#1e293b'),
        borderWidth: 1,
      },
    ],
  }

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'bottom' as const,
        labels: {
          color: '#94a3b8',
          usePointStyle: true,
          padding: 20,
        },
      },
      tooltip: {
        backgroundColor: '#1e293b',
        titleColor: '#f8fafc',
        bodyColor: '#f8fafc',
        borderColor: '#334155',
        borderWidth: 1,
        padding: 12,
        displayColors: true,
        callbacks: {
          label: function (tooltipItem: TooltipItem<'pie'>) {
            const value = tooltipItem.raw as number
            return `${value}%`
          },
        },
      },
    },
  }

  return (
    <div className={className}>
      <Pie data={chartData} options={options} />
    </div>
  )
}
