import React from 'react'
import { useParams, NavLink } from 'react-router-dom'
import * as Tabs from '@radix-ui/react-tabs'
import { motion } from 'framer-motion'
import { ChevronRight, FileText } from 'lucide-react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { Grid } from '@/shared/components/ui/grid'

// Mock data for demonstration
const mockData = [
  {
    id: 'LRPLY00645987',
    status: 'Repair',
    productName: 'Cloudmonster 2',
    productSKU: '#2M3ME1012',
    size: 'UK11',
    outcomeOnAssessment: 'TST',
    originChannel: 'Online - Direct from Consumer',
    currentFacility: 'USCA005 - California, United States',
    timeInRepair: 1.45,
    carbonSavings: 0.08,
    repairCost: 16.35,
    repairCostTrend: 2.1,
    timeInRepairTrend: 0.25,
    productImageUrls: ['/images/shoe-front.jpg', '/images/shoe-side.jpg'],
    requiredComponents: [
      { component: 'Insole', cost: 2.34 },
      { component: 'Laces', cost: 0.86 },
      { component: 'Decal', cost: 0.23 },
    ],
    trackingHistory: [
      {
        title: 'Repair Started',
        location: 'USCA005 - California, United States',
        date: '11 Oct at 11:54',
      },
      { title: 'Assessment', location: '', date: '11 Oct at 08:28' },
      {
        title: 'Outcome Logged',
        location: 'USCA005 - California, United States',
        date: '11 Oct at 08:12',
      },
      {
        title: 'Inspection',
        location: 'USCA005 - California, United States',
        date: '11 Oct at 08:12',
      },
      { title: 'Received', location: 'California, United States', date: '10 Oct at 16:15' },
      {
        title: 'Collection Scan',
        location: '200 West Arbor Drive, San Diego, CA 92103',
        date: '6 Oct at 16:54',
      },
      {
        title: 'Label Generated',
        location: '200 West Arbor Drive, San Diego, CA 92103',
        date: '21 Sep at 10:14',
      },
    ],
  },
  // Add more mock data items as needed
]

export function RepairDetailPage() {
  const { id } = useParams<{ id: string }>()
  const data = mockData.find(item => item.id === id) || mockData[0] // Fallback to first item if not found

  return (
    <motion.div
      className='text-text-primary'
      initial={{ opacity: 0, y: 10 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.3 }}
    >
      <nav className='flex items-center text-sm text-text-secondary mb-4'>
        <NavLink to='/admin/repairs' className='hover:text-text-primary'>
          Repairs
        </NavLink>
        <ChevronRight size={16} className='mx-2' />
        <span className='text-text-secondary'>Repair Information</span>
        <ChevronRight size={16} className='mx-2' />
        <span className='text-text-primary font-medium'>{data.id}</span>
      </nav>

      <header className='flex items-center justify-between mb-6'>
        <div className='flex items-center gap-2'>
          <Text variant='h1'>{data.id}</Text>
          <span className='text-sm bg-bg-panel text-text-primary px-2 py-1 rounded'>
            {data.status}
          </span>
        </div>
      </header>

      <Tabs.Root defaultValue='overview' className='space-y-6'>
        <Tabs.List className='flex space-x-4 border-b border-border-base'>
          <Tabs.Trigger
            value='overview'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary'
          >
            Overview
          </Tabs.Trigger>
          <Tabs.Trigger
            value='assess'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary'
          >
            Assess
          </Tabs.Trigger>
          <Tabs.Trigger
            value='repair'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary'
          >
            Repair
          </Tabs.Trigger>
          <Tabs.Trigger
            value='resale'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary'
          >
            Resale
          </Tabs.Trigger>
          <Tabs.Trigger
            value='recycle'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary'
          >
            Recycle
          </Tabs.Trigger>
          <Tabs.Trigger
            value='documents'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary flex items-center gap-1'
          >
            <FileText size={14} />
            Documents
          </Tabs.Trigger>
          <Tabs.Trigger
            value='history'
            className='px-3 py-2 text-sm text-text-secondary data-[state=active]:border-b-2 data-[state=active]:border-accent-purple data-[state=active]:text-text-primary'
          >
            History
          </Tabs.Trigger>
        </Tabs.List>

        <Tabs.Content value='overview' className='space-y-8'>
          <Grid columns={3} gap={6}>
            <Card>
              <Text variant='h3' className='text-text-primary'>
                Product Details
              </Text>
              <div className='mt-4 space-y-2'>
                <div className='flex justify-between'>
                  <Text variant='label' className='text-text-secondary'>
                    Name
                  </Text>
                  <Text className='text-text-primary'>{data.productName}</Text>
                </div>
                <div className='flex justify-between'>
                  <Text variant='label' className='text-text-secondary'>
                    SKU
                  </Text>
                  <Text className='text-text-primary'>{data.productSKU}</Text>
                </div>
                <div className='flex justify-between'>
                  <Text variant='label' className='text-text-secondary'>
                    Size
                  </Text>
                  <Text className='text-text-primary'>{data.size}</Text>
                </div>
              </div>
            </Card>

            <Card>
              <Text variant='h3' className='text-text-primary'>
                Repair Status
              </Text>
              <div className='mt-4 space-y-2'>
                <div className='flex justify-between'>
                  <Text variant='label' className='text-text-secondary'>
                    Time in Repair
                  </Text>
                  <Text className='text-text-primary'>{data.timeInRepair} days</Text>
                </div>
                <div className='flex justify-between'>
                  <Text variant='label' className='text-text-secondary'>
                    Carbon Savings
                  </Text>
                  <Text className='text-text-primary'>{data.carbonSavings} kg CO2e</Text>
                </div>
                <div className='flex justify-between'>
                  <Text variant='label' className='text-text-secondary'>
                    Repair Cost
                  </Text>
                  <Text className='text-text-primary'>${data.repairCost}</Text>
                </div>
              </div>
            </Card>

            <Card>
              <Text variant='h3' className='text-text-primary'>
                Required Components
              </Text>
              <div className='mt-4 space-y-2'>
                {data.requiredComponents.map((component, index) => (
                  <div key={index} className='flex justify-between'>
                    <Text variant='label' className='text-text-secondary'>
                      {component.component}
                    </Text>
                    <Text className='text-text-primary'>${component.cost}</Text>
                  </div>
                ))}
              </div>
            </Card>
          </Grid>

          <Card>
            <Text variant='h3' className='text-text-primary'>
              Tracking History
            </Text>
            <div className='mt-4 space-y-4'>
              {data.trackingHistory.map((event, index) => (
                <div key={index} className='flex items-start gap-4'>
                  <div className='w-2 h-2 mt-2 rounded-full bg-accent-purple' />
                  <div className='flex-1'>
                    <Text variant='label' className='text-text-primary'>
                      {event.title}
                    </Text>
                    {event.location && (
                      <Text className='text-text-secondary'>{event.location}</Text>
                    )}
                    <Text className='text-text-tertiary'>{event.date}</Text>
                  </div>
                </div>
              ))}
            </div>
          </Card>
        </Tabs.Content>
      </Tabs.Root>
    </motion.div>
  )
}
