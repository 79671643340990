import React from 'react'
import { AdminLayout } from './components/layout/admin-layout'
import { DashboardPage } from './components/dashboard/dashboard-page'
import { InboundListPage } from './components/inbound/inbound-list-page'
import { InboundDetailPage } from './components/inbound/inbound-detail-page'
import { RefillProgramPage } from './components/refills/refill-program-page'
import { RefillOverviewPage } from './components/refills/refill-overview-page'
import { RefillPackagingFlowsPage } from './components/refills/refill-packaging-flows-page'
import { RefillReturnInformationPage } from './components/refills/refill-return-information-page'
import { RefillBoxDetailPage } from './components/refills/refill-box-detail-page'
import { RepairProgramPage } from './components/repairs/repair-program-page'
import { RepairOverviewPage } from './components/repairs/repair-overview-page'
import { RepairsListPage } from './components/repairs/repairs-list-page'
import { RepairDetailPage } from './components/repairs/repair-detail-page'
import { RepairTechniciansPage } from './components/repairs/repair-technicians-page'
import { RepairAnalyticsPage } from './components/repairs/repair-analytics-page'
import { RecycleProgramPage } from './components/recycle/recycle-program-page'
import { RecycleOverviewPage } from './components/recycle/recycle-overview-page'
import { RecycleFinancialsPage } from './components/recycle/recycle-financials-page'
import { RecycleEnvironmentPage } from './components/recycle/recycle-environment-page'
import { RecycleInformationPage } from './components/recycle/recycle-information-page'
import { AILearningPage } from './components/ai-learning/ai-learning-page'
import { AILearningStreamDetail } from './components/ai-learning/ai-learning-stream-detail'
import { ResaleDetailPage } from './components/resale/resale-detail-page'
import { ResaleEnvironmentPage } from './components/resale/resale-environment-page'
import { ResaleFinancialsPage } from './components/resale/resale-financials-page'
import { ResaleInformationPage } from './components/resale/resale-information-page'
import { ResaleOverviewPage } from './components/resale/resale-overview-page'
import { ResaleProgramPage } from './components/resale/resale-program-page'

const routes = [
  {
    path: '/admin',
    element: <AdminLayout />,
    children: [
      { index: true, element: <DashboardPage /> },
      { path: 'inbound', element: <InboundListPage /> },
      { path: 'inbound/:id', element: <InboundDetailPage /> },
      {
        path: 'refills',
        element: <RefillProgramPage />,
        children: [
          { path: 'overview', element: <RefillOverviewPage /> },
          { path: 'packaging-flows', element: <RefillPackagingFlowsPage /> },
          { path: 'return-information', element: <RefillReturnInformationPage /> },
          { path: 'box/:id', element: <RefillBoxDetailPage /> },
        ],
      },
      {
        path: 'repairs',
        element: <RepairProgramPage />,
        children: [
          { path: 'overview', element: <RepairOverviewPage /> },
          { path: 'list', element: <RepairsListPage /> },
          { path: 'technicians', element: <RepairTechniciansPage /> },
          { path: 'analytics', element: <RepairAnalyticsPage /> },
          { path: ':id', element: <RepairDetailPage /> },
        ],
      },
      {
        path: 'recycle',
        element: <RecycleProgramPage />,
        children: [
          { path: 'overview', element: <RecycleOverviewPage /> },
          { path: 'financials', element: <RecycleFinancialsPage /> },
          { path: 'environment', element: <RecycleEnvironmentPage /> },
          { path: 'recycle-information', element: <RecycleInformationPage /> },
        ],
      },
      {
        path: 'resale',
        element: <ResaleProgramPage />,
        children: [
          { path: 'overview', element: <ResaleOverviewPage /> },
          { path: 'financials', element: <ResaleFinancialsPage /> },
          { path: 'environment', element: <ResaleEnvironmentPage /> },
          { path: 'resale-information', element: <ResaleInformationPage /> },
          { path: 'resale-information/:id', element: <ResaleDetailPage /> },
        ],
      },
      { path: 'ai-learning', element: <AILearningPage /> },
      { path: 'ai-learning/stream/:stream', element: <AILearningStreamDetail /> },
    ],
  },
]

export function AdminRoutes() {
  return routes
}
