import { setupWorker } from 'msw/browser'
import { handlers } from './handlers'

export const worker = setupWorker(...handlers)

// Create a promise that resolves when MSW is ready
export const mswReady = 
  worker
      .start({
        onUnhandledRequest: 'bypass',
        serviceWorker: {
          url: '/mockServiceWorker.js',
        },
      })
      .catch((error) => {
        console.error('MSW initialization failed:', error)
        return Promise.reject(error)
      })