import React from 'react'
import { Text } from '@/shared/components/ui/text'
import { Card } from '@/shared/components/ui/card'
import { LineChart } from '@/shared/components/ui/charts/line-chart'
import { BarChart } from '@/shared/components/ui/charts/bar-chart'

export const ResaleFinancialsPage: React.FC = () => {
  return (
    <div className='space-y-8'>
      <Text variant='h3'>Financials</Text>

      <div className='grid grid-cols-4 gap-6'>
        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Total Revenue
          </Text>
          <Text variant='h3' className='mt-2'>
            $123,456
          </Text>
          <Text variant='body' className='text-green-400 text-sm'>
            +12.5% vs last month
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Average Margin
          </Text>
          <Text variant='h3' className='mt-2'>
            45%
          </Text>
          <Text variant='body' className='text-red-400 text-sm'>
            -2.3% vs last month
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Repair Costs
          </Text>
          <Text variant='h3' className='mt-2'>
            $34,567
          </Text>
          <Text variant='body' className='text-neutral-400 text-sm'>
            +1.2% vs last month
          </Text>
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Net Profit
          </Text>
          <Text variant='h3' className='mt-2'>
            $88,889
          </Text>
          <Text variant='body' className='text-green-400 text-sm'>
            +15.7% vs last month
          </Text>
        </Card>
      </div>

      <div className='grid grid-cols-2 gap-6'>
        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Revenue Trend
          </Text>
          <LineChart
            data={[
              { timestamp: 1701734400000, value: 123456 },
              { timestamp: 1701648000000, value: 115678 },
              { timestamp: 1701561600000, value: 98765 },
              { timestamp: 1701475200000, value: 87654 },
              { timestamp: 1701388800000, value: 76543 },
            ]}
            className='h-64'
          />
        </Card>

        <Card className='p-4'>
          <Text variant='label' className='text-neutral-400'>
            Profit Margin by Category
          </Text>
          <BarChart
            data={[
              { label: 'Shoes', value: 45 },
              { label: 'Apparel', value: 38 },
              { label: 'Accessories', value: 52 },
              { label: 'Equipment', value: 41 },
            ]}
            className='h-64'
          />
        </Card>
      </div>
    </div>
  )
}
