import { useMemo } from 'react'
import type {
  RefillDashboardStats,
  RefillSubscriptionAnalytics,
  ContainerAnalytics,
} from '../components/refills/types'

export function useRefillAnalytics() {
  const dashboardStats = useMemo<RefillDashboardStats>(
    () => ({
      totalActiveSubscriptions: 1234,
      averageRefillFrequency: 14,
      containerReuseRate: 92,
      carbonFootprintSaved: 5678,
      customerRetentionRate: 95,
    }),
    []
  )

  const subscriptionAnalytics = useMemo<RefillSubscriptionAnalytics>(
    () => ({
      totalSubscribers: 1500,
      churnRate: 5,
      averageOrderValue: 45,
      subscriptionsByFrequency: {
        weekly: 300,
        biweekly: 500,
        monthly: 700,
      },
      topProducts: [
        {
          product: {
            id: '1',
            name: 'Eco Shampoo',
            description: 'Natural shampoo in refillable container',
            price: 12.99,
            imageUrl: '/images/shampoo.jpg',
            tags: ['eco-friendly', 'refillable'],
            refillDiscount: 0.2,
            isRefillable: true,
            minimumRefillInterval: 14,
            recommendedRefillInterval: 30,
            availableSizes: ['250ml', '500ml'],
            inStoreAvailability: true,
            sustainabilityScore: 9.5,
          },
          subscriberCount: 450,
          revenue: 5850.5,
        },
      ],
    }),
    []
  )

  const containerAnalytics = useMemo<ContainerAnalytics>(
    () => ({
      totalContainers: 5000,
      averageLifespan: 365,
      reuseRateByProduct: {
        'eco-shampoo': 95,
        'eco-soap': 92,
      },
      conditionDistribution: {
        acceptable: 80,
        needs_cleaning: 15,
        damaged: 5,
      },
      replacementRate: 8,
    }),
    []
  )

  return {
    dashboardStats,
    subscriptionAnalytics,
    containerAnalytics,
  }
}
