import { graphql, HttpResponse } from 'msw'
import { mockProcessedItems, mockMetrics } from '../dashboard/mock-data'

export const handlers = [
  graphql.query('GetDashboardMetrics', () => {
    return HttpResponse.json({
      data: {
        dashboardMetrics: {
          processedItems: mockProcessedItems,
          metrics: mockMetrics
        }
      }
    })
  }),

  graphql.query('GetProcessedItems', () => {
    return HttpResponse.json({
      data: {
        processedItems: mockProcessedItems
      }
    })
  }),

  graphql.query('GetMetricOverviews', () => {
    return HttpResponse.json({
      data: {
        metricOverviews: mockMetrics
      }
    })
  })
] 