import { DashboardMetrics, ProcessedItemsData, MetricOverview } from './types'
import { mswReady } from '../mocks/browser'

// GraphQL endpoint configuration
const GRAPHQL_ENDPOINT = '/api/graphql'

// GraphQL query strings
const DASHBOARD_METRICS_QUERY = `
  query GetDashboardMetrics {
    dashboardMetrics {
      processedItems {
        date
        resale
        donateResale
        recycle
      }
      metrics {
        label
        value
        change
        trend
        unit
      }
    }
  }
`

const PROCESSED_ITEMS_QUERY = `
  query GetProcessedItems {
    processedItems {
      date
      resale
      donateResale
      recycle
    }
  }
`

const METRIC_OVERVIEWS_QUERY = `
  query GetMetricOverviews {
    metricOverviews {
      label
      value
      change
      trend
      unit
    }
  }
`

// GraphQL client helper
async function graphqlRequest<T>(query: string): Promise<T> {
  try {
    // Ensure MSW is ready before making requests in development
    // if (process.env.NODE_ENV === 'development') {
      await mswReady
    // }

    const response = await fetch(GRAPHQL_ENDPOINT, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query })
    })

    if (!response.ok) {
      throw new Error(`GraphQL request failed: ${response.status} ${response.statusText}`)
    }

    const { data, errors } = await response.json()
    if (errors) {
      console.error('GraphQL Errors:', errors)
      throw new Error(errors[0].message)
    }

    return data
  } catch (error) {
    console.error('GraphQL Request Error:', error)
    throw error
  }
}

export const dashboardService = {
  getMetrics: async (): Promise<DashboardMetrics> => {
    const data = await graphqlRequest<{ dashboardMetrics: DashboardMetrics }>(DASHBOARD_METRICS_QUERY)
    return data.dashboardMetrics
  },

  getProcessedItems: async (): Promise<ProcessedItemsData[]> => {
    const data = await graphqlRequest<{ processedItems: ProcessedItemsData[] }>(PROCESSED_ITEMS_QUERY)
    return data.processedItems
  },

  getMetricOverviews: async (): Promise<MetricOverview[]> => {
    const data = await graphqlRequest<{ metricOverviews: MetricOverview[] }>(METRIC_OVERVIEWS_QUERY)
    return data.metricOverviews
  }
}
